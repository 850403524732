
// import httpx from './co.api.httpx'
// import comm from './co.api.comm'
// import url from './co.api.url'

// authModes 授权登录方式
const AuthModes = {
  // sms 验证码方式
  sms: 'sms',
  // password 密码方式
  password: 'password'
}

// smsScopes 验证码用途
const SmsScopes = {
  login: 'login',
  register: 'register',
  forgot: 'forgot',
  changeMobile: 'changeMobile'
}

const CaptTypes = {
  IMAGE: '0',
  SMS: '1',
  EMAIL: '2'
}

const CaptCache = {
  key: '',
  scope: '',
  type: CaptTypes.IMAGE,
  receiver: ''
}

const apiCaptcha = {
  Types: CaptTypes,
  Modes: AuthModes,
  Scopes: SmsScopes,
  cache: CaptCache,
  getKey (imgSrc) {
    if (imgSrc) {
      const index = imgSrc.lastIndexOf('0')
      return imgSrc.slice(index + 1)
    } else {
      return null
    }
  },
  getCode ({
    scope,
    type,
    receiver,
    executing = null,
    executed = null
  }) {
  },
  getCaptcha () {
    return CaptCache
  },
}

export default apiCaptcha
