<template>
  <div
    class="co-flex-row co-items-center"
    :class="[margin]"
    style="cursor: pointer;"
    @click="onClick"
  >
    <v-icon
      :size="size"
      :color="color || 'blue-grey'"
    >
      {{ icon }}
    </v-icon>
    <span
      class="text-body-1 ml-1"
      :class="[color ? `${color}--text` : 'blue-grey--text']"
    >
      {{ value || 0 }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'LandIconLike',
    props: {
      name: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: 'mdi-star-outline'
      },
      value: {
        type: [Number, String],
        default: ''
      },
      color: {
        type: String,
        default: 'blue-grey'
      },
      size: {
        type: [Number, String],
        default: 20
      },
      margin: {
        type: String,
        default: 'mr-4'
      }
    },
    methods: {
      onClick () {
        this.$emit('action', {
          name: this.name
        })
      }
    }
  }
</script>
