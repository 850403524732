/**
 * 接口地址
 */
// 6013 为生产地址
const BASE_URL = 'http://server.zuimeideguang.com:6013'

// 6008 为测试地址
// const BASE_URL = 'http://server.zuimeideguang.com:6008'

const apiUrl = {
  web_im_socket: 'ws://server.zuimeideguang.com:6013/websocket',
  // web_im_socket: 'ws://server.zuimeideguang.com:6008/websocket',

  web_activity_list: BASE_URL + '/web/activity/list',
  web_activity_detail: BASE_URL + '/web/activity/details',
  web_activity_current: BASE_URL + '/web/activity/getCurrent',

  web_activity_enroll_list: BASE_URL + '/web/activityEnroll/list',
  web_activity_enroll_create: BASE_URL + '/web/activityEnroll/create',
  web_activity_enroll_delete: BASE_URL + '/web/activityEnroll/delete',
  web_activity_enroll_update: BASE_URL + '/web/activityEnroll/update',
  web_activity_enroll_detail: BASE_URL + '/web/activityEnroll/details',
  web_activity_enroll_current: BASE_URL + '/web/activityEnroll/getCurrent',

  web_album_img_list: BASE_URL + '/web/albumImage/list',
  web_album_img_create: BASE_URL + '/web/albumImage/create',
  web_album_img_delete: BASE_URL + '/web/albumImage/delete',
  web_album_img_update: BASE_URL + '/web/albumImage/update',
  web_album_img_detail: BASE_URL + '/web/albumImage/details',

  web_album_list: BASE_URL + '/web/album/list',
  web_album_list_personal: BASE_URL + '/web/album/personalList',
  web_album_albums_personal: BASE_URL + '/web/album/getPersonalAlbums',
  web_album_albums_public: BASE_URL + '/web/album/getPersonalPublic',
  web_album_create: BASE_URL + '/web/album/create',
  web_album_delete: BASE_URL + '/web/album/delete',
  web_album_update: BASE_URL + '/web/album/update',
  web_album_detail: BASE_URL + '/web/album/details',

  web_banner_list: BASE_URL + '/web/banner/list',
  web_banner_detail: BASE_URL + '/web/banner/details',

  web_category_list: BASE_URL + '/web/category/list',
  web_category_detail: BASE_URL + '/web/category/details',

  web_circle_subscribe_list: BASE_URL + '/web/circleSubscribe/list',
  web_circle_subscribe_create: BASE_URL + '/web/circleSubscribe/create',
  web_circle_subscribe_delete: BASE_URL + '/web/circleSubscribe/delete',
  web_circle_subscribe_detail: BASE_URL + '/web/circleSubscribe/details',

  web_circle_list: BASE_URL + '/web/circle/list',
  web_circle_create: BASE_URL + '/web/circle/create',
  web_circle_delete: BASE_URL + '/web/circle/delete',
  web_circle_update: BASE_URL + '/web/circle/update',
  web_circle_detail: BASE_URL + '/web/circle/details',

  web_collection_list: BASE_URL + '/web/collection/list',
  web_collection_list_personal: BASE_URL + '/web/collection/personalList',
  web_collection_create: BASE_URL + '/web/collection/create',
  web_collection_delete: BASE_URL + '/web/collection/delete',
  web_collection_update: BASE_URL + '/web/collection/update',
  web_collection_detail: BASE_URL + '/web/collection/details',

  web_comment_list: BASE_URL + '/web/comment/list',
  web_comment_list_personal: BASE_URL + '/web/comment/personalList',
  web_comment_list_sub: BASE_URL + '/web/comment/getSubComment',
  web_comment_create: BASE_URL + '/web/comment/create',
  web_comment_delete: BASE_URL + '/web/comment/delete',
  web_comment_detail: BASE_URL + '/web/comment/details',

  web_content_list: BASE_URL + '/web/content/list',
  web_content_list_hot: BASE_URL + '/web/content/hotList',
  web_content_list_personal: BASE_URL + '/web/content/personalList',
  web_content_list_all_circle: BASE_URL + '/web/content/getAllCircleContent',
  web_content_list_attention_circle: BASE_URL + '/web/content/getAttentionCircleContent',
  web_content_list_attention: BASE_URL + '/web/content/attentionList',
  web_content_list_side: BASE_URL + '/web/content/getSidebar',
  web_content_create: BASE_URL + '/web/content/create',
  web_content_delete: BASE_URL + '/web/content/delete',
  web_content_update: BASE_URL + '/web/content/update',
  web_content_detail: BASE_URL + '/web/content/details',

  web_dict_list: BASE_URL + '/web/dict/list',
  web_dict_detail: BASE_URL + '/web/dict/details',

  web_fans_list: BASE_URL + '/web/fans/list',
  web_fans_list_personal: BASE_URL + '/web/fans/personalList',
  web_fans_create: BASE_URL + '/web/fans/create',
  web_fans_delete: BASE_URL + '/web/fans/delete',
  web_fans_detail: BASE_URL + '/web/fans/details',

  web_guestbook_create: BASE_URL + '/web/guestbook/create',
  web_guestbook_delete: BASE_URL + '/web/guestbook/delete',
  web_guestbook_detail: BASE_URL + '/web/guestbook/details',

  web_im_msg_delete: BASE_URL + '/web/imMsg/delete',
  web_im_msg_create: BASE_URL + '/web/imMsg/create',
  web_im_msg_record: BASE_URL + '/web/imMsg/record',

  web_im_session_delete: BASE_URL + '/web/imSession/delete',
  web_im_session_record: BASE_URL + '/web/imSession/record',

  web_like_list: BASE_URL + '/web/likes/list',
  web_like_list_personal: BASE_URL + '/web/likes/personalList',
  web_like_create: BASE_URL + '/web/likes/create',
  web_like_delete: BASE_URL + '/web/likes/delete',
  web_like_update: BASE_URL + '/web/likes/update',
  web_like_detail: BASE_URL + '/web/likes/details',

  web_reply_list: BASE_URL + '/web/reply/list',
  web_reply_list_personal: BASE_URL + '/web/reply/personalList',
  web_reply_create: BASE_URL + '/web/reply/create',
  web_reply_delete: BASE_URL + '/web/reply/delete',
  web_reply_detail: BASE_URL + '/web/reply/details',

  web_search_list: BASE_URL + '/web/search/list',

  web_topic_list: BASE_URL + '/web/topic/list',
  web_topic_detail: BASE_URL + '/web/topic/details',

  web_behaviour_attention: BASE_URL + '/web/userBehaviour/attention',
  web_behaviour_collection: BASE_URL + '/web/userBehaviour/collection',
  web_behaviour_like: BASE_URL + '/web/userBehaviour/likes',
  web_behaviour_forward: BASE_URL + '/web/userBehaviour/forwarded',
  web_behaviour_subscribe: BASE_URL + '/web/userBehaviour/subscribeCircle',
  web_behaviour_cancel_attention: BASE_URL + '/web/userBehaviour/cancelAttention',
  web_behaviour_cancel_collection: BASE_URL + '/web/userBehaviour/cancelCollection',
  web_behaviour_cancel_like: BASE_URL + '/web/userBehaviour/cancelLike',
  web_behaviour_cancel_subscribe: BASE_URL + '/web/userBehaviour/cancelSubscribeCircle',

  web_user_get_attention_flag: BASE_URL + '/web/user/getAttentionFlag',
  web_user_get_extend: BASE_URL + '/web/user/getExtendInfo',
  web_user_get_extend_by: BASE_URL + '/web/user/getOtherExtendInfo',
  web_user_get_extend_content: BASE_URL + '/web/user/getContentExtendInfo',
  web_user_update: BASE_URL + '/web/user/update',
  web_user_info_by: BASE_URL + '/web/user/getUserInfo',

  web_wx_mp_qrcode: BASE_URL + '/web/wxMp/generatorQrCode',
  web_wx_mp_login: BASE_URL + '/web/wxMp/login',
  web_wx_mp_scan: BASE_URL + '/web/wxMp/scan',
  web_wx_mp_check_qrcode: BASE_URL + '/web/wxMp/checkQrcode',
  web_wx_mp_expiredQrcode: BASE_URL + '/web/wxMp/expiredQrcode',
  web_wx_mp_get_ticket: BASE_URL + '/web/wxMp/getTicket',

  web_util_file_delete: BASE_URL + '/web/util/fileDelete',
  web_util_file_upload: BASE_URL + '/web/util/fileUpload',

  web_sso_login: BASE_URL + '/web/sso/login',
  web_sso_logout: BASE_URL + '/web/sso/logout',
  web_sso_signup: BASE_URL + '/web/sso/signup',
  web_sso_refresh: BASE_URL + '/web/sso/refreshToken',
  web_sso_verify: BASE_URL + '/web/sso/verifyToken',
  web_sso_send_msg_code: BASE_URL + '/web/sso/sendMsgCode',
  web_sso_forget_pwd: BASE_URL + '/web/sso/forgetPwd',
  web_sso_set_pwd: BASE_URL + '/web/sso/setPwd',
  web_sso_bind_mobile: `${BASE_URL}/web/sso/bindMobile`,
  web_sso_update_pwd_by_mobile: `${BASE_URL}/web/sso/updatePwdByMobileCode`,
  web_sso_update_pwd_by_oldpwd: `${BASE_URL}/web/sso/updatePwdByOldPwd`,
  web_sso_get_user_info: BASE_URL + '/web/sso/getUserInfo',
}

export default apiUrl
