
import app from '@/api/co.app'

const Types = {
  USER: 'user',
  DOCTOR: 'doctor',
  STUDENT: 'student'
}

const TypeDescs = {
  [Types.USER]: {
    badge: '',
    avatar: app.pic.avatar_user,
    text: '普通用户',
    value: Types.USER,
    type: 'blue-grey',
  },
  [Types.DOCTOR]: {
    badge: app.pic.id_doctor,
    avatar: app.pic.avatar_doctor,
    text: '医师',
    value: Types.DOCTOR,
    type: 'red',
  },
  [Types.STUDENT]: {
    badge: app.pic.id_student,
    avatar: app.pic.avatar_student,
    text: '医学生',
    value: Types.STUDENT,
    type: 'red',
  }
}

const coUserComm = {
  Types,
  TypeDescs
}

export default coUserComm
