<template>
  <el-popover
    :ref="`cmt_${name}`"
    placement="bottom"
    width="400"
    trigger="click"
    :disabled="!enabled"
  >
    <div class="co-flex-col">
      <el-input
        v-model="content"
        placeholder="发布你的评论"
        maxlength="64"
        show-word-limit
        required
        :rules="rules"
      />
      <div class="co-flex-col co-justify-end co-items-end co-mt4">
        <el-button
          size="medium"
          type="primary"
          @click="onComment"
        >
          评论
        </el-button>
      </div>
    </div>
    <div
      slot="reference"
      class="co-flex-row co-items-center"
      :class="[margin]"
      :style="{cursor: enabled ? 'pointer' : 'default'}"
    >
      <v-icon
        :size="size"
        :color="color || 'blue-grey'"
      >
        {{ icon }}
      </v-icon>
      <span
        class="text-body-1 ml-1"
        :class="[color ? `${color}--text` : 'blue-grey--text']"
      >
        {{ value || 0 }}
      </span>
    </div>
  </el-popover>
</template>

<script>
  export default {
    name: 'LandIconComment',
    props: {
      name: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: 'mdi-comment-processing-outline'
      },
      value: {
        type: [Number, String],
        default: ''
      },
      color: {
        type: String,
        default: 'blue-grey'
      },
      size: {
        type: [Number, String],
        default: 20
      },
      margin: {
        type: String,
        default: 'mr-4'
      },
      enabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        content: '',
        rules: [
          v => !!v || '必须填写评论',
          v => v.length <= 64 || '评论不能超过64个字符',
        ]
      }
    },
    methods: {
      onComment () {
        const name = `cmt_${this.name}`
        const frm = this.$refs[name]

        if (frm) {
          frm.doClose()
        }

        const params = {
          name: this.name,
          content: this.content
        }
        this.$emit('action', params)
      }
    }
  }
</script>
