<template>
  <land-section
    :space="space"
    :dark="dark"
    :color="backColor"
  >
    <v-container>
      <v-row
        justify="space-between"
        align="start"
      >
        <v-col
          cols="12"
          md="6"
        >
          <land-business-info
            title="联系方式"
            :dark="dark"
            :assist-color="assistColor"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <land-contact-form
            :dark="dark"
            :title="contact.title"
            :color="assistColor"
            :button-text="contact.buttonText"
            :submiting="contact.submiting"
            @submit="onSubmit"
          />
        </v-col>
      </v-row>
    </v-container>
    <land-dialog
      :ref="dlgHint.name"
    />
  </land-section>
</template>

<script>
  // import mixContact from '@/pages/mixins/mix.contact'
  export default {
    name: 'LandContactWay',
    // mixins: [mixContact],
    props: {
      dark: {
        type: Boolean,
        default: false
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      space: {
        type: [Number, String],
        default: 60
      }
    },
    data () {
      return {}
    }
  }
</script>
