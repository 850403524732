
import api from '@/api/co.api.js'
import app from '@/api/co.app.js'

import comm from './co.user.comm'
import local from '@/api/lib/co.lib.local'

const userInfo = {
  cache: api.comm.jsonToObject(local.get(app.keys.user_info)),
  save (data) {
    Object.assign(userInfo.cache, data)
    local.set(app.keys.user_info, JSON.stringify(data))
  },
  get () {
    const str = local.get(app.keys.user_info)
    const info = api.comm.jsonToObject(str)
    return info
  },
  remove () {
    userInfo.cache = {}
    local.remove(app.keys.user_info)
  },
   /**
  * pull 根据 id 获取用户信息
  */
   pull ({
    id,
    executing,
    executed
  }) {
    const params = api.comm.createParams({
      url: `${api.url.web_user_info_by}/${id}`,
      data: null,
      executing,
      executed,
      transform: userInfo.convertItem
    })

    api.httpx.getDetail(params)
  },
  attentionFlag ({
    userId,
    executing,
    executed
  }) {
    api.httpx.pull({
      url: `${api.url.web_user_get_attention_flag}/${userId}`,
      data: {
        userId
      },
      executing,
      executed,
    })
  },
  /**
   * extend 获取用户扩展信息（如粉丝数、关注数、发布数等）
   */
  extend ({
    executing,
    executed
  }) {
    api.httpx.pull({
      url: api.url.web_user_get_extend,
      data: null,
      executing,
      executed,
    })
  },
  extendBy ({
    userId,
    executing,
    executed
  }) {
    api.httpx.pull({
      url: `${api.url.web_user_get_extend_by}/${userId}`,
      data: null,
      executing,
      executed,
    })
  },
  /**
   * extendContent 获取用户内容扩展信息（如转发、点赞、收藏等）
   */
  extendContent ({
    contentId,
    executing,
    executed
  }) {
    api.httpx.pull({
      url: `${api.url.web_user_get_extend_content}/${contentId}`,
      data: {
        contentId
      },
      executing,
      executed,
    })
  },
  /**
  * gain 根据token 获取用户信息
  */
  gain ({
    executing,
    executed
  }) {
    const me = userInfo
    const ended = function (res) {
      const result = {
        status: res.status,
        data: res.data
      }

      if (res.status) {
        result.data = me.convertItem(res.data)
      }

      if (typeof executed === 'function') {
        executed(result)
      }
    }

    api.httpx.pull({
      url: api.url.web_sso_get_user_info,
      data: null,
      executing,
      executed: ended,
    })
  },
  update ({
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.push({
      url: api.url.web_user_update,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    const typeDesc = comm.TypeDescs[srcItem.loginType] || {}
    const dst = {
      ...srcItem,
      typeDesc,
      badge: typeDesc.badge || '',
      avatar: srcItem.avatar || typeDesc.avatar || app.pic.avatar_user,
      mobileMask: api.comm.phoneMask(srcItem.mobile || '')
    }
    return dst
  },
}

export default userInfo
