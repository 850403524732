<template>
  <v-theme-provider dark>
    <section id="land-hero-alts">
      <v-carousel
        cycle
        :interval="interval"
        :height="wdHeight"
        :hide-delimiters="hideDelimiters"
        :show-arrows="showArrows"
        hide-delimiter-background
      >
        <v-carousel-item
          v-for="(item, index) in items"
          :key="index"
        >
          <land-img
            transition="scale-transition"
            height="100%"
            :src="item.cover"
            :gradient="titled ? gradient : ''"
            flat
            max-width="100%"
            tile
            @click="onClick(item, index)"
          >
            <v-row
              v-if="titled && item.title"
              align="center"
              class="ma-0 fill-height text-center"
              justify="center"
            >
              <v-col cols="12">
                <land-heading
                  :title="item.title"
                  space="3"
                  weight="500"
                  align="center"
                />

                <land-divider
                  v-if="divisible"
                  :color="assistColor"
                  align="center"
                  space="8"
                />

                <v-breadcrumbs
                  v-if="breadcrumbs && breadcrumbs.length"
                  :items="breadcrumbs"
                  class="justify-center pa-0"
                  divider=">"
                  large
                />
              </v-col>
            </v-row>
          </land-img>
        </v-carousel-item>
      </v-carousel>
    </section>
  </v-theme-provider>
</template>

<script>
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'LandHeroAlts',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      height: {
        type: [Number, String],
        default: 360
      },
      points: {
        type: Object,
        default: () => {
          return {
            xs: 200,
            sm: 300,
            md: 360,
            lg: 360,
            xl: 360
          }
        }
      },
      breadcrumbs: {
        type: Array,
        default: () => {
          return []
        }
      },
      cycle: {
        type: Boolean,
        default: true
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      divisible: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 5000
      },
      hideDelimiters: {
        type: Boolean,
        default: true
      },
      showArrows: {
        type: Boolean,
        default: false
      },
      titled: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    },
    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.secondary}66`
        const color2 = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))
        return `to top right, ${overlay1}, ${overlay2}`
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || this.height
        return ht
      },
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }

        this.$emit('click', params)
      }
    }
  }
</script>

<style lang="sass">
  #land-hero-alts
    .v-breadcrumbs__item
      color: #FFFFFF
</style>
