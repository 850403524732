
/**
 * 当前资源文件存放路径
 * /data/home/docker/nginx/html
 */

import base from './co.app.base.js'

const appPic = {
  id_doctor: base.res_skin + '/icon/img-id-doctor.png',
  id_student: base.res_skin + '/icon/img-id-student.png',
  md_douyin_w: base.res_skin + '/icon/img-md-douyin-w.png',
  md_douyin: base.res_skin + '/icon/img-md-douyin.png',
  md_shipin_w: base.res_skin + '/icon/img-md-shipin-w.png',
  md_shipin: base.res_skin + '/icon/img-md-shipin.png',
  md_weibo_w: base.res_skin + '/icon/img-md-weibo-w.png',
  md_weibo: base.res_skin + '/icon/img-md-weibo.png',
  md_weixin_w: base.res_skin + '/icon/img-md-weixin-w.png',
  md_weixin: base.res_skin + '/icon/img-md-weixin.png',

  avatar_doctor: base.res_skin + '/icon/avatar-doctor.png',
  avatar_student: base.res_skin + '/icon/avatar-student.png',
  avatar_user: base.res_skin + '/icon/avatar-user.png',

  cover_activity: base.res_skin + '/assist/cover-activity.png',
  cover_contact: base.res_skin + '/assist/cover-contact.png',
  cover_login: base.res_skin + '/assist/cover-login.png',
  cover_my: base.res_skin + '/assist/cover-my.png',

  img_upload_bg: base.res_skin + '/assist/img-upload-bg.png',
  img_album_bg: base.res_skin + '/assist/img-album-bg.png',
  img_about_media: base.res_skin + '/assist/img-about-media.png',
  img_about_media_lg: base.res_skin + '/assist/img-about-media-lg.png',
}

export default appPic
