
// import { message } from 'ant-design-vue'

const ImageTypes = ['image/jpeg', 'image/jgp', 'image/png', 'image/gif']

const StateStyles = {
  BLUE: 'lan-status-blue',
  YELLOW: 'lan-status-yellow',
  RED: 'lan-status-red',
  BLACK: 'lan-status-black',
  GREEN: 'lan-status-green',
  TEAL: 'lan-status-teal',
  CYAN: 'lan-status-cyan',
  ORANGE: 'lan-status-orange',
  GRAY: 'lan-status-gray',
  PURPLE: 'lan-status-purple',
  BLUE_DARK: 'lan-status-blue-dark',
  YELLOW_DARK: 'lan-status-yellow-dark',
  RED_DARK: 'lan-status-red-dark',
  BLACK_DARK: 'lan-status-black-dark',
  GREEN_DARK: 'lan-status-green-dark',
  TEAL_DARK: 'lan-status-teal-dark',
  CYAN_DARK: 'lan-status-cyan-dark',
  ORANGE_DARK: 'lan-status-orange-dark',
  GRAY_DARK: 'lan-status-gray-dark',
  PURPLE_DARK: 'lan-status-purple-dark',
  BLUE_PLAIN: 'lan-status-blue-plain',
  YELLOW_PLAIN: 'lan-status-yellow-plain',
  RED_PLAIN: 'lan-status-red-plain',
  BLACK_PLAIN: 'lan-status-black-plain',
  GREEN_PLAIN: 'lan-status-green-plain',
  TEAL_PLAIN: 'lan-status-teal-plain',
  CYAN_PLAIN: 'lan-status-cyan-plain',
  ORANGE_PLAIN: 'lan-status-orange-plain',
  GRAY_PLAIN: 'lan-status-gray-plain',
  PURPLE_PLAIN: 'lan-status-purple-plain'
}

const SearchKeys = {
  SEARCH: 'search',
  STATUS: 'status',
  EXTEND: 'extend',
  DATE: 'date',
  DATETIME: 'datetime',
  ACTIVITY: 'activityId',
  COMPANY: 'companyId',
  REVIEWER: 'reviewerId',
  TOPIC: 'topic',
  MAINTYPECODE: 'mainTypeCode'
}

const FileTypes = {
  pdf: 'pdf',
  doc: 'doc',
  xls: 'xls',
  ppt: 'ppt',
  img: 'img',
  oth: 'oth'
}

const FileIcons = {
  pdf: 'mdi-file-star-outline',
  doc: 'mdi-file-word-outline',
  xls: 'mdi-file-excel-outline',
  ppt: 'mdi-file-powerpoint-outline',
  img: 'mdi-file-image-outline',
  oth: 'mdi-file-question-outline'
}

const FileIconColors = {
  pdf: 'red ',
  doc: 'blue',
  xls: 'green',
  ppt: 'orange',
  img: 'purple ',
  oth: 'blue-grey darken-4'
}

const FileSuffixes = {
  pdf: '.pdf',
  doc: '.doc,.docx',
  xls: '.xls,.xlsx',
  ppt: '.ppt,.pptx',
  img: '.png,.jpg,.gif,.bmp,.jpeg'
}

const FileStates = {
  ADDING: 'adding',
  DELETING: 'deleting',
  UPLOADED: 'uploaded'
}

const MaterialTypes = {
  IMG: 'image',
  PDF: 'pdf',
  DOC: 'doc',
  XLS: 'xls',
  PPT: 'ppt',
  MSO: 'mso',
  ALL: 'all'
}

const MaterialSizes = {
  IMG_MAX_SIZE: 1024 * 1024 * 2,
  PDF_MAX_SIZE: 1024 * 1024 * 20,
  MSO_MAX_SIZE: 1024 * 1024 * 20,
  BASE_SIZE: 1024 * 1024
}

const MaterialNotes = {
  [MaterialTypes.IMG]: {
    type: MaterialTypes.IMG,
    title: '图片',
    tip: '只能上传图片',
    filter: '.jpg,.jpeg,.png,.gif,.bmp',
    maxSize: MaterialSizes.IMG_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.IMG_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  },
  [MaterialTypes.PDF]: {
    type: MaterialTypes.PDF,
    title: 'PDF文件',
    tip: '只能上传PDF文件',
    filter: '.pdf',
    maxSize: MaterialSizes.PDF_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.PDF_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  },
  [MaterialTypes.DOC]: {
    type: MaterialTypes.DOC,
    title: 'Word文件',
    tip: '只能上传Word文件',
    filter: '.doc,.docx',
    maxSize: MaterialSizes.MSO_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.MSO_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  },
  [MaterialTypes.XLS]: {
    type: MaterialTypes.XLS,
    title: 'Excel文件',
    tip: '只能上传Excel文件',
    filter: '.xls,.xlsx',
    maxSize: MaterialSizes.MSO_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.MSO_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  },
  [MaterialTypes.PPT]: {
    type: MaterialTypes.PPT,
    title: 'PPT文件',
    tip: '只能上传PPT文件',
    filter: '.ppt,.pptx',
    maxSize: MaterialSizes.MSO_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.MSO_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  },
  [MaterialTypes.MSO]: {
    type: MaterialTypes.MSO,
    title: 'Office文件',
    tip: '只能上传Office文件',
    filter: '.doc,.docx,.xls,.xlsx,.ppt,.pptx',
    maxSize: MaterialSizes.MSO_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.MSO_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  },
  [MaterialTypes.ALL]: {
    type: MaterialTypes.ALL,
    title: '文件',
    tip: '只能上传PDF与Office文件',
    filter: '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx',
    maxSize: MaterialSizes.MSO_MAX_SIZE,
    maxSizeTitle: (MaterialSizes.MSO_MAX_SIZE / MaterialSizes.BASE_SIZE).toFixed(0) + 'MB'
  }
}

const ContentModels = {
  SHOP: 'shop',
  ARTICLE: 'article'
}

const ContentModelDescs = {
  [ContentModels.SHOP]: {
    text: '图片模式',
    value: ContentModels.SHOP,
    style: StateStyles.YELLOW
  },
  [ContentModels.ARTICLE]: {
    text: '富文本模式',
    value: ContentModels.ARTICLE,
    style: StateStyles.ORANGE
  }
}

const Enableds = {
  ENABLED: '1',
  DISABLED: '0'
}

const EnabledDescs = {
  [Enableds.ENABLED]: {
    text: '启用',
    value: Enableds.ENABLED,
    style: StateStyles.GREEN_PLAIN
  },
  [Enableds.DISABLED]: {
    text: '禁用',
    value: Enableds.DISABLED,
    style: StateStyles.BLACK_PLAIN
  }
}

const ContentStates = {
  PUBLISH: 'publish',
  DOWN: 'down'
}

const CommentStates = {
  ALLOW: '1',
  FORBID: '0'
}

const ContentStateDescs = {
  [ContentStates.PUBLISH]: {
    text: '开放',
    value: ContentStates.PUBLISH,
    style: StateStyles.GREEN_PLAIN
  },
  [ContentStates.DOWN]: {
    text: '隐藏',
    value: ContentStates.DOWN,
    style: StateStyles.BLACK_PLAIN
  }
}

const CommentStateDescs = {
  [CommentStates.ALLOW]: {
    text: '允许',
    value: CommentStates.ALLOW,
    style: StateStyles.GREEN
  },
  [CommentStates.FORBID]: {
    text: '禁止',
    value: CommentStates.FORBID,
    style: StateStyles.BLACK
  }
}

/*
* LoadTypes 数据加载状态
*/
const LoadTypes = {
  IDLE: 0,
  LOADING: 1,
  FINISH: 2,
  FAIL: 3,
  EMPTY: 4
}

const MessageTypes = {
  EMPTY: '',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
}

const MessageEffects = {
  DEFAULT: 'light',
  LIGHT: 'light',
  DARK: 'dark',
  PLAIN: 'plain'
}

const WidgetTypes = {
  EMPTY: '',
  PRIMARY: 'primary',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger'
}

const KindStyles = {
  // 80 * 80
  ICON80: 'show-icon80',
  // 96 * 96
  ICON96: 'show-icon96',
  // 120 * 120
  ICON120: 'show-icon120',
  // 风景视图
  SIGHT: 'show-sight',
  // 条幅
  BLOCK: 'show-block',
  // 瀑布流
  WATERFALL: 'show-waterfall',
  // catalog 目录
  CATALOG: 'show-catalog',
  // part 栏目，仅用于内容分类
  // 前端根据相关配置提取相关栏目的内容；
  // 一般不用于上述类型那样展示在前端页面中
  PART: 'show-part'
}

const UrlTargets = {
  // 跳转到指定超链接
  HREF: 'url',
  // 跳转到指定图片
  IMAGE: 'image',
  // 跳转到指定文件
  FILE: 'file'
}

const KindStyleDescs = {
  [KindStyles.ICON80]: {
    text: '80图标',
    value: KindStyles.ICON80,
    style: StateStyles.BLUE
  },
  [KindStyles.ICON96]: {
    text: '96图像',
    value: KindStyles.ICON96,
    style: StateStyles.BLUE
  },
  [KindStyles.ICON120]: {
    text: '120图像',
    value: KindStyles.ICON120,
    style: StateStyles.BLUE
  },
  [KindStyles.SIGHT]: {
    text: '风景视图',
    value: KindStyles.SIGHT,
    style: StateStyles.GREEN
  },
  [KindStyles.BLOCK]: {
    text: '条幅',
    value: KindStyles.BLOCK,
    style: StateStyles.TEAL
  },
  [KindStyles.WATERFALL]: {
    text: '瀑布流',
    value: KindStyles.WATERFALL,
    style: StateStyles.ORANGE
  },
  [KindStyles.CATALOG]: {
    text: '目录',
    value: KindStyles.CATALOG,
    style: StateStyles.GREEN
  },
  [KindStyles.PART]: {
    text: '栏目',
    value: KindStyles.PART,
    style: StateStyles.BLACK
  }
}

const UrlTargetDescs = {
  [UrlTargets.HREF]: {
    text: '网址',
    value: UrlTargets.HREF,
    style: StateStyles.BLACK
  },
  [UrlTargets.IMAGE]: {
    text: '图片',
    value: UrlTargets.IMAGE,
    style: StateStyles.ORANGE
  },
  [UrlTargets.FILE]: {
    text: '文件',
    value: UrlTargets.FILE,
    style: StateStyles.GREEN
  }
}

const TargetTypes = {
  EMPTY: 0,
  NOTICE: 1,
  GOODS: 2,
  ALL: 3
}

const NoticeTypes = {
  NONE: 'notice_none',
  // BANNER 轮播图
  BANNER: 'notice_banner',
  // MESSAGE 公告
  MESSAGE: 'notice_message',
  // NEWS 资讯
  NEWS: 'notice_news',
  // WELFARE 活动
  WELFARE: 'notice_welfare',
  // EXTEND 小型条幅
  EXTEND: 'notice_extend',
  // INFO 通知
  INFO: 'notice_info',
  // AWARD 奖励
  AWARD: 'notice_award',
  // summary
  SUMMARY: 'notice_summary',
  // CUSTOM 自定义
  CUSTOM: 'notice_custom'
}

const NoticeTypeDescs = {
  [NoticeTypes.BANNER]: {
    text: '轮播图',
    value: NoticeTypes.BANNER,
    style: StateStyles.BLUE
  },
  [NoticeTypes.INFO]: {
    text: '通知',
    value: NoticeTypes.INFO,
    style: StateStyles.CYAN
  },
  [NoticeTypes.NEWS]: {
    text: '资讯',
    value: NoticeTypes.NEWS,
    style: StateStyles.GREEN
  },
  [NoticeTypes.MESSAGE]: {
    text: '公告',
    value: NoticeTypes.MESSAGE,
    style: StateStyles.YELLOW
  },
  [NoticeTypes.WELFARE]: {
    text: '活动',
    value: NoticeTypes.WELFARE,
    style: StateStyles.RED
  },
  [NoticeTypes.EXTEND]: {
    text: '条幅',
    value: NoticeTypes.EXTEND,
    style: StateStyles.BLUE
  },
  [NoticeTypes.AWARD]: {
    text: '福利',
    value: NoticeTypes.AWARD,
    style: StateStyles.ORANGE
  },
  [NoticeTypes.SUMMARY]: {
    text: '摘要',
    value: NoticeTypes.SUMMARY,
    style: StateStyles.ORANGE
  },
  [NoticeTypes.CUSTOM]: {
    text: '自定义',
    value: NoticeTypes.CUSTOM,
    style: StateStyles.BLACK
  }
}

const LimitNums = [
  { text: '1件', value: '1', hint: '仅能购1件' },
  { text: '2件', value: '2', hint: '可以购2件' },
  { text: '3件', value: '3', hint: '可以购3件' },
  { text: '4件', value: '4', hint: '可以购4件' },
  { text: '5件', value: '5', hint: '可以购5件' }
]

const GroupNums = [
  { text: '2人团', value: '2', hint: '需2人可成团' },
  { text: '3人团', value: '3', hint: '需3人可成团' },
  { text: '4人团', value: '4', hint: '需4人可成团' },
  { text: '5人团', value: '5', hint: '需5人可成团' },
  { text: '6人团', value: '6', hint: '需6人可成团' },
  { text: '7人团', value: '7', hint: '需7人可成团' },
  { text: '8人团', value: '8', hint: '需8人可成团' },
  { text: '9人团', value: '9', hint: '需9人可成团' }
]

const GroupValidity = [
  { text: '30分钟', value: '30', hint: '0.5h内有效' },
  { text: '60分钟', value: '60', hint: '1h内有效时' },
  { text: '90分钟', value: '90', hint: '1.5h内有效' },
  { text: '120分钟', value: '120', hint: '2h内有效' },
  { text: '180分钟', value: '180', hint: '3h内有效' },
  { text: '300分钟', value: '300', hint: '5h内有效' },
  { text: '480分钟', value: '480', hint: '8h内有效' },
  { text: '600分钟', value: '600', hint: '10h内有效' }
]

const GoodsTypes = {
  CARGO: 'content-goods',
  VIRTUAL: 'content-virtual',
  ARTICLE: 'content-article',
  BOOKING: 'content-reserved',
  MISSION: 'content-mission',
  KILLER: 'content-killer',
  GROUP: 'content-group',
  SPECIAL: 'content-special',
  COMBO: 'content-combo',
  PREORDER: 'content-preorder'
}

const GoodsTypeDescs = {
  [GoodsTypes.CARGO]: {
    text: '通用商品',
    value: GoodsTypes.CARGO,
    style: StateStyles.BLUE,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [GoodsTypes.VIRTUAL]: {
    text: '虚拟商品',
    value: GoodsTypes.VIRTUAL,
    style: StateStyles.CYAN,
    visible: true,
    disabled: false,
    type: 'warning',
    effect: 'light'
  },
  [GoodsTypes.ARTICLE]: {
    text: '文章商品',
    value: GoodsTypes.ARTICLE,
    style: StateStyles.TEAL,
    visible: true,
    disabled: false,
    type: 'info',
    effect: 'light'
  },
  [GoodsTypes.BOOKING]: {
    text: '预约商品',
    value: GoodsTypes.BOOKING,
    style: StateStyles.BLACK,
    visible: true,
    disabled: false,
    type: 'success',
    effect: 'light'
  },
  [GoodsTypes.KILLER]: {
    text: '秒杀商品',
    value: GoodsTypes.KILLER,
    style: StateStyles.PURPLE_DARK,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [GoodsTypes.GROUP]: {
    text: '拼团商品',
    value: GoodsTypes.GROUP,
    style: StateStyles.ORANGE_DARK,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [GoodsTypes.SPECIAL]: {
    text: '特价商品',
    value: GoodsTypes.SPECIAL,
    style: StateStyles.RED_DARK,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [GoodsTypes.COMBO]: {
    text: '组合商品',
    value: GoodsTypes.COMBO,
    style: StateStyles.TEAL_DARK,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [GoodsTypes.MISSION]: {
    text: '任务',
    value: GoodsTypes.MISSION,
    style: StateStyles.BLUE,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [GoodsTypes.PREORDER]: {
    text: '预订商品',
    value: GoodsTypes.PREORDER,
    style: StateStyles.CYAN,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  }
}

// let glbLoading = null

const DateTypes = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  BEFOREYESTERDAY: 'beforeyesterday',
  BEFOREWEEK: 'beforeweek'
}

const DateRangeTypes = {
  ONEWEEK: 'oneweek',
  TWOWEEK: 'twoweek',
  THREEWEEK: 'threeweek',
  ONEMONTH: 'onemonth',
  TWOMONTH: 'twomonth',
  THREEMONTH: 'threemonth'
}

const TimeUnits = {
  年: 31557600000,
  月: 2629800000,
  天: 86400000,
  小时: 3600000,
  分钟: 60000,
  秒: 1000
}

const apiComm = {
  gutter: 20,
  span1: 24,
  span2: 12,
  span3: 8,
  LimitNums,
  GroupNums,
  GroupValidity,
  TargetTypes,
  UrlTargets,
  UrlTargetDescs,
  KindStyles,
  KindStyleDescs,
  NoticeTypes,
  NoticeTypeDescs,
  DateTypes,
  DateRangeTypes,
  GoodsTypes,
  GoodsTypeDescs,
  MessageEffects,
  WidgetTypes,
  LoadTypes,
  FileIcons,
  FileIconColors,
  FileStates,
  FileTypes,
  MessageTypes,
  MaterialTypes,
  Enableds,
  EnabledDescs,
  Models: ContentModels,
  ModelDescs: ContentModelDescs,
  ContentStates,
  ContentStateDescs,
  CommentStates,
  CommentStateDescs,
  SearchKeys,
  StateStyles,
  MAX_LEN_TEXT: 250,
  MIN_LEN_TEXT: 2,
  NO_DATA_TEXT: '暂无数据',
  IMG_MAX_SIZE: MaterialSizes.IMG_MAX_SIZE,
  PDF_MAX_SIZE: MaterialSizes.PDF_MAX_SIZE,
  MSO_MAX_SIZE: MaterialSizes.MSO_MAX_SIZE,
  BASE_FILE_SIZE: MaterialSizes.BASE_SIZE,
  humanize (milliseconds) {
    if (milliseconds <= 1000) {
      return '刚刚'
    }
    let humanize = ''
    for (const key in TimeUnits) {
      const tu = TimeUnits[key]
      if (milliseconds >= tu) {
        humanize = Math.floor(milliseconds / tu) + key + '前'
        break
      }
    }
    return humanize || '刚刚'
  },
  getFileType (fileSuffix) {
    let fileType = FileTypes.oth
    for (const key in FileSuffixes) {
      const type = FileSuffixes[key]
      const ind = type.indexOf(fileSuffix)
      if (ind !== -1) {
        fileType = FileTypes[key]
        break
      }
    }
    return fileType
  },
  parsePath (path) {
    let name = ''
    let type = FileTypes.oth
    let suffix = ''
    let dir = ''
    let nameEx = ''
    let icon = ''
    let color = FileIconColors.oth
    let viewable = false

    if (path) {
      let ind = path.lastIndexOf('/')
      if (ind >= 0) {
        viewable = true
        dir = path.substring(0, ind + 1)
        const temp = path.substring(ind + 1)
        nameEx = temp
        ind = temp.lastIndexOf('.')
        if (ind >= 0) {
          suffix = temp.substring(ind)
          name = temp.substring(0, ind)
          type = this.getFileType(suffix)
          icon = FileIcons[type] || FileIcons.oth
          color = FileIconColors[type] || FileIconColors.oth
        }
      } else {
        const ind = path.lastIndexOf('.')
        if (ind >= 0) {
          suffix = path.substring(ind)
          name = path.substring(0, ind)
          type = this.getFileType(suffix)
          icon = FileIcons[type] || FileIcons.oth
          color = FileIconColors[type] || FileIconColors.oth
        }
        nameEx = path
      }
    }

    const file = {
      path,
      name,
      type,
      suffix,
      dir,
      nameEx,
      icon,
      color,
      viewable
    }
    return file
  },
  getEnabledDesc (value) {
    let result = EnabledDescs[value]
    if (!result) {
      result = {
        text: '未定义',
        value,
        style: StateStyles.GRAY_PLAIN
      }
    }

    return result
  },
  getEnabledDescs (addAll = false) {
    const items = []

    if (addAll) {
      items.push({
        text: '所有状态',
        value: ''
      })
    }
    for (const key in EnabledDescs) {
      const item = EnabledDescs[key]
      items.push({ ...item })
    }

    return items
  },
  createParams ({
    url,
    data = null,
    items = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const obj = {
      url,
      data,
      items,
      detail: {},
      reload: false,
      reversed: false,
      loadState: 'ready',
      executing,
      executed,
      transform
    }
    return obj
  },
  trim (str) {
    if (!str) {
      return ''
    }

    return str.replace(/^\s+|\s+$/g, '')
  },
  ltrim (str) {
    if (!str) {
      return ''
    }

    return str.replace(/^\s*/, '')
  },
  rtrim (str) {
    if (!str) {
      return ''
    }

    return str.replace(/(\s*$)/g, '')
  },
  splitCRLF (str) {
    if (!str) {
      return []
    }

    const arr = str.split(/[(\r\n)\r\n]+/)
    arr.forEach((item, index) => {
      if (!item) {
        arr.splice(index, 1)
      }
    })
    return arr
  },
  cutEnding (src, target) {
    const haveEnd = this.isEnding(src, target)
    if (haveEnd) {
      const start = src.length - target.length
      return src.substr(0, start)
    } else {
      return src
    }
  },
  isEnding (src, target) {
    if (!src || !target) {
      return false
    }
    const start = src.length - target.length
    const frag = src.substr(start, target.length)
    if (frag === target) {
      return true
    } else {
      return false
    }
  },
  isPhone (str) {
    const reg = /^[1][3-9][0-9]{9}$/
    if (reg.test(str)) {
      return true
    } else {
      return false
    }
  },
  phoneMask (str) {
    let result = ''
    if (apiComm.isPhone(str)) {
      result = str.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    }
    return result
  },
  phoneFormat (str) {
    let result = ''
    if (apiComm.isPhone(str)) {
      result = str.replace(/^(.{3})(.*)(.{4})$/, '$1 $2 $3')
    }
    return result
  },
  getDate (dateType = DateTypes.TODAY) {
    let _date = null
    switch (dateType) {
      case DateTypes.TODAY:
        _date = new Date()
        break
      case DateTypes.YESTERDAY:
        _date = new Date()
        _date.setTime(_date.getTime() - 3600 * 1000 * 24)
        break
      case DateTypes.BEFOREYESTERDAY:
        _date = new Date()
        _date.setTime(_date.getTime() - 3600 * 1000 * 24 * 2)
        break
      case DateTypes.BEFOREWEEK:
        _date = new Date()
        _date.setTime(_date.getTime() - 3600 * 1000 * 24 * 7)
        break
      default:
        _date = new Date()
        break
    }
    return _date
  },
  getDateRange (dateRangeType = DateRangeTypes.ONEWEEK) {
    let start = null
    let end = null

    switch (dateRangeType) {
      case DateRangeTypes.ONEWEEK:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        break
      case DateRangeTypes.TWOWEEK:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
        break
      case DateRangeTypes.THREEWEEK:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 21)
        break
      case DateRangeTypes.ONEMONTH:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        break
      case DateRangeTypes.TWOMONTH:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
        break
      case DateRangeTypes.THREEMONTH:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        break
      default:
        end = new Date()
        start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        break
    }
    return [start, end]
  },
  getDateOption () {
    const option = {
      disabledDate (time) {
        return time.getTime() > Date.now()
      },
      shortcuts: [{
        text: '今天',
        onClick (picker) {
          const date = apiComm.getDate()
          picker.$emit('pick', date)
        }
      }, {
        text: '昨天',
        onClick (picker) {
          const date = apiComm.getDate(DateTypes.YESTERDAY)
          picker.$emit('pick', date)
        }
      }, {
        text: '前天',
        onClick (picker) {
          const date = apiComm.getDate(DateTypes.BEFOREYESTERDAY)
          picker.$emit('pick', date)
        }
      }, {
        text: '一周前',
        onClick (picker) {
          const date = apiComm.getDate(DateTypes.BEFOREWEEK)
          picker.$emit('pick', date)
        }
      }]
    }

    return option
  },
  getDateRangeOption () {
    const option = {
      disabledDate (time) {
        return time.getTime() > Date.now()
      },
      shortcuts: [{
        text: '最近一周',
        onClick (picker) {
          const startEnd = apiComm.getDateRange(DateRangeTypes.ONEWEEK)
          picker.$emit('pick', startEnd)
        }
      }, {
        text: '最近二周',
        onClick (picker) {
          const startEnd = apiComm.getDateRange(DateRangeTypes.TWOWEEK)
          picker.$emit('pick', startEnd)
        }
      }, {
        text: '最近三周',
        onClick (picker) {
          const startEnd = apiComm.getDateRange(DateRangeTypes.THREEWEEK)
          picker.$emit('pick', startEnd)
        }
      }, {
        text: '最近一个月',
        onClick (picker) {
          const startEnd = apiComm.getDateRange(DateRangeTypes.ONEMONTH)
          picker.$emit('pick', startEnd)
        }
      }, {
        text: '最近二个月',
        onClick (picker) {
          const startEnd = apiComm.getDateRange(DateRangeTypes.TWOMONTH)
          picker.$emit('pick', startEnd)
        }
      }, {
        text: '最近三个月',
        onClick (picker) {
          const startEnd = apiComm.getDateRange(DateRangeTypes.THREEMONTH)
          picker.$emit('pick', startEnd)
        }
      }]
    }
    return option
  },
  createDescription (type) {
    const text = type ? '未定义' : '默认'
    const value = type || 'empty'

    const desc = {
      text,
      value,
      style: StateStyles.GRAY,
      visible: true,
      disabled: false,
      type: 'info',
      effect: 'plain'
    }
    return desc
  },
  getCommentStateDescription (status) {
    let desc = CommentStateDescs[status]
    if (!desc) {
      desc = this.createDescription(status)
    }
    return desc
  },
  getCommentStateDescriptions (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in CommentStateDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...CommentStateDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有状态',
        value: includes.join('|')
      })
    }

    return items
  },
  getKindTypeDescription (type) {
    let desc = KindStyleDescs[type]
    if (!desc) {
      desc = this.createDescription(type)
    }
    return desc
  },
  getKindTypeDescriptions (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in KindStyleDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...KindStyleDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有样式',
        value: includes.join('|')
      })
    }

    return items
  },
  getUrlTargetTypeDescriptions (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in UrlTargetDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...UrlTargetDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有',
        value: includes.join('|')
      })
    }

    return items
  },
  getNoticeTypeDescription (type) {
    let desc = NoticeTypeDescs[type]
    if (!desc) {
      desc = this.createDescription(type)
    }
    return desc
  },
  getNoticeTypeDescriptions (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in NoticeTypeDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...NoticeTypeDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有类型',
        value: includes.join('|')
      })
    }

    return items
  },
  getGoodsTypeDescription (type) {
    let desc = GoodsTypeDescs[type]
    if (!desc) {
      desc = this.createDescription(type)
    }
    return desc
  },
  getGoodsTypeDescriptions (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in GoodsTypeDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...GoodsTypeDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有类型',
        value: includes.join('|')
      })
    }

    return items
  },
  getContentTypeDescription (type) {
    let desc = NoticeTypeDescs[type]
    if (!desc) {
      desc = GoodsTypeDescs[type]
    }

    if (!desc) {
      desc = this.createDescription(type)
    }
    return desc
  },
  getContentTypeDescriptions (type = 3, addAll = false) {
    const _items = []
    if (addAll) {
      _items.push({
        text: '所有类型',
        value: ''
      })
    }

    const _targets1 = []
    if ((type & TargetTypes.NOTICE) === TargetTypes.NOTICE) {
      for (const key1 in NoticeTypeDescs) {
        const _item = NoticeTypeDescs[key1]
        _items.push(_item)
        _targets1.push(_item.value)
      }
    }

    const _targets2 = []
    if ((type & TargetTypes.GOODS) === TargetTypes.GOODS) {
      for (const key2 in GoodsTypeDescs) {
        const _item = GoodsTypeDescs[key2]
        _items.push(_item)
        _targets2.push(_item.value)
      }
    }

    return _items
  },
  getModelDesc (model) {
    let item = null
    if (model) {
      item = ContentModelDescs[model]
    }

    if (!item) {
      item = ContentModelDescs[ContentModels.ARTICLE]
    }

    return item || {}
  },
  getModelDescs (addAll = false, excludes = []) {
    const items = []
    const includes = []
    for (const key in ContentModelDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...ContentModelDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有模型',
        value: includes.join('|')
      })
    }

    return items
  },
  getMaterialNote (materialType) {
    let _note = MaterialNotes[materialType]
    if (!_note) {
      _note = {
        type: materialType,
        title: '文件',
        tip: '上传所有类型文件',
        filter: '.*',
        maxSize: MaterialSizes.MSO_MAX_SIZE
      }
    }

    return _note
  },
  convertImagesToELFiles (images) {
    const _files = []
    for (const i in images) {
      const _item = images[i]
      if (_item.type === 'image') {
        _files.push({
          type: _item.type,
          name: _item.name || `图像${i}`,
          url: _item.value,
          size: _item.size || 0
        })
      }
    }
    return _files
  },
  convertFilesToELFiles (files) {
    const _files = []
    for (const i in files) {
      const _item = files[i]
      _files.push({
        type: _item.type || '',
        name: _item.name || `文件${i}`,
        url: _item.value,
        size: _item.size || 0
      })
    }
    return _files
  },
  createRule ({ message, type = 'text', required = true, min = 0, max = 0 }) {
    const _rules = []
    let _msg = message
    switch (type) {
      case 'select':
        _msg = _msg || '请选择类型'
        _rules.push({
          required,
          message: _msg,
          trigger: 'change'
        })
        break
      case 'date':
        _msg = _msg || '请选择日期'
        _rules.push({
          type,
          required,
          message: _msg,
          trigger: 'change'
        })
        break
      case 'array':
        _msg = _msg || '请至少选择一个类型'
        _rules.push({
          type,
          required,
          message: _msg,
          trigger: 'change'
        })
        break
      case 'phone':
        console.warn('### 未实现功能!!!')
        // const validatePhone = (rule, value, callback) => {
        //   const reg = /^[1][3-9][0-9]{9}$/
        //   if (reg.test(value)) {
        //     callback()
        //   } else {
        //     callback(new Error('请输入正确的手机号码'))
        //   }
        // }
        // _msg = _msg || '请输入手机号码'
        // _rules.push({
        //   required,
        //   message: _msg,
        //   trigger: 'blur'
        // })
        // _rules.push({
        //   validator: validatePhone, trigger: 'blur'
        // })
        break
      case 'integer':
        console.warn('### 未实现功能!!!')
        // const validateInteger = (rule, value, callback) => {
        //   const reg = /^\d+$/
        //   if (value) {
        //     if (reg.test(value)) {
        //       callback()
        //     } else {
        //       callback(new Error('请输入正确的整数值'))
        //     }
        //   } else {
        //     callback()
        //   }
        // }
        // _msg = _msg || '请输入整数值'
        // _rules.push({
        //   required,
        //   message: _msg,
        //   trigger: 'blur'
        // })
        // _rules.push({
        //   validator: validateInteger, trigger: ['blur', 'change']
        // })
        break
      case 'float':
        console.warn('### 未实现功能!!!')
        // const validateFloat = (rule, value, callback) => {
        //   const reg = /^\d+(\.\d+)?$/
        //   if (value) {
        //     if (reg.test(value)) {
        //       callback()
        //     } else {
        //       callback(new Error('请输入正确的数字值'))
        //     }
        //   } else {
        //     callback()
        //   }
        // }
        // _msg = _msg || '请输入数字值'
        // _rules.push({
        //   required,
        //   message: _msg,
        //   trigger: 'blur'
        // })
        // _rules.push({
        //   validator: validateFloat, trigger: ['blur', 'change']
        // })
        break
      case 'email':
        _msg = _msg || '请输入邮箱地址'
        _rules.push({
          required,
          message: _msg,
          trigger: 'blur'
        })
        _rules.push({
          type,
          message: '请输入正确的邮箱地址',
          trigger: ['blur', 'change']
        })
        break
      default:
        _msg = _msg || '请输入内容'
        _rules.push({
          required,
          message: _msg,
          trigger: 'blur'
        })

        if (min && max) {
          const _msg2 = `长度在 ${min} 到 ${max} 个字符`
          _rules.push({
            min,
            max,
            message: _msg2,
            trigger: ['blur', 'change']
          })
        }
        break
    }
    // console.log('land.comm createRule, type: %o, rules:%o', type, _rules)
    return _rules
  },
  createTextRule (message, min = 0, max = 0) {
    const _rules = []
    const _msg1 = message || '请输入内容'
    _rules.push({
      required: true,
      message: _msg1,
      trigger: 'blur'
    })

    if (min && max) {
      const _msg2 = `长度在 ${min} 到 ${max} 个字符`
      _rules.push({
        min,
        max,
        message: _msg2,
        trigger: 'blur'
      })
    }
    return _rules
  },
  createSelectRule (message) {
    const _rules = []
    const _msg1 = message || '请选择类型'
    _rules.push({
      required: true,
      message: _msg1,
      trigger: 'change'
    })
    return _rules
  },
  createDateRule (message) {
    const _rules = []
    const _msg1 = message || '请选择日期'
    _rules.push({
      type: 'date',
      required: true,
      message: _msg1,
      trigger: 'change'
    })
    return _rules
  },
  createArrayRule (message) {
    const _rules = []
    const _msg1 = message || '请至少选择一个类型'
    _rules.push({
      type: 'array',
      required: true,
      message: _msg1,
      trigger: 'change'
    })
    return _rules
  },
  createExportColumns (tableColumns) {
    const _exportCols = []
    if (tableColumns) {
      tableColumns.forEach(col => {
        if (col.enabledExport !== false) {
          _exportCols.push({
            label: col.title,
            prop: col.key
          })
        }
      })
    }
    return _exportCols
  },
  getContentModel (model) {
    const _item = apiComm.ContentModelValues[model]
    if (_item) {
      return _item
    } else {
      return {
        text: '图片模式',
        value: ContentModels.SHOP
      }
    }
  },
  getContentModels (hasAll = false) {
    const _models = {
      title: apiComm.ContentModelValues.title,
      holder: apiComm.ContentModelValues.holder,
      value: ContentModels.ARTICLE,
      type: 'select',
      filterable: false,
      style: 'width: 130px;',
      showTitle: false,
      options: []
    }

    if (hasAll) {
      _models.options.push({
        text: '全部模式',
        value: ''
      })
    }

    let _item = apiComm.getContentModel(ContentModels.SHOP)
    _models.options.push(apiComm.clone(_item))
    _item = apiComm.getContentModel(ContentModels.ARTICLE)
    _models.options.push(apiComm.clone(_item))

    return _models
  },
  createSearchConfig () {
    const _cfg = {
      type: 'input',
      title: '关键字',
      showTitle: false,
      holder: '输入查找关键字',
      style: 'width: 240px;'
    }

    return _cfg
  },
  createDateConfig () {
    const _cfg = {
      type: 'date',
      title: '',
      showTitle: false,
      holder: '请选择日期',
      style: 'width: 150px;',
      options: null
    }

    return _cfg
  },
  createDateRangeConfig () {
    const _cfg = {
      type: 'daterange',
      title: '',
      showTitle: false,
      holderstart: '开始日期',
      holderend: '结束日期',
      style: 'width: 280px;',
      options: null
    }

    return _cfg
  },
  createExtendConfig () {
    const _cfg = {
      type: 'extend',
      title: '关键字',
      showTitle: false,
      holder: '输入查找内容',
      style: 'width: 320px;',
      value: '',
      prepend: {
        style: 'width: 110px;',
        holder: '请选择',
        options: []
      }
    }

    return _cfg
  },
  createDatetimeConfig () {
    const _cfg = {
      type: 'datetime',
      style: 'width: 360px;'
    }

    return _cfg
  },
  getContentState (state) {
    const item = ContentStateDescs[state] || {
      text: '下架',
      value: state,
      style: StateStyles.BLACK_PLAIN
    }
    return item
  },
  getContentStates (hasAll = false) {
    const items = []
    if (hasAll) {
      items.push({
        text: '所有状态',
        value: ''
      })
    }

    for (const key in ContentStateDescs) {
      items.push({
        ...ContentStateDescs[key]
      })
    }
    return items
  },

  getCommentState (state) {
    const item = CommentStateDescs[state] || {
      text: '禁止',
      value: CommentStates.FORBID,
      style: StateStyles.BLACK
    }

    return item
  },
  getCommentStates (hasAll = false) {
    const items = []
    if (hasAll) {
      items.push({
        text: '所有状态',
        value: ''
      })
    }

    for (const key in CommentStateDescs) {
      items.push({
        ...CommentStateDescs[key]
      })
    }
    return items
  },
  viewFile (file) {
    let _url = ''
    let _name = ''
    let _path = ''
    _name = file.name || file.fileName
    if (/\.(pdf|PDF)$/.test(_name)) {
      _url = file.url || file.value || file.path
    } else if (/\.(jpg|jpeg|png|gif|bmp)$/.test(_name)) {
      _url = file.url || file.path || file.value || file.image
    } else {
      _path = file.url || file.path || file.value
      _url = `https://view.officeapps.live.com/op/view.aspx?src=${_path}`
    }
    if (_url) {
      window.open(_url, '_blank')
    }
  },
  showMessage ({
    content = '',
    type = MessageTypes.INFO,
    duration = 5
  }) {
    console.warn('### co.api.comm showMessage 未实现功能!!!')
    // if (!content) {
    //   return
    // }
    // switch (type) {
    //   case MessageTypes.INFO:
    //     message.info(content, duration)
    //     break
    //   case MessageTypes.SUCCESS:
    //     message.success(content, duration)
    //     break
    //   case MessageTypes.ERROR:
    //     message.error(content, duration)
    //     break
    //   case MessageTypes.WARNING:
    //     message.warning(content, duration)
    //     break
    //   default:
    //     message.info(content, duration)
    //     break
    // }
  },
  showLoading ({
    content = ''
  }) {
    console.warn('### co.api.comm showLoading 未实现功能!!!')
    // glbLoading = message.loading(content, 0)
  },
  hideLoading () {
    console.warn('### co.api.comm hideLoading 未实现功能!!!')
    // if (glbLoading) {
    //   setTimeout(glbLoading, 100)
    // }
  },
  verifyFiles ({ verifySize = true, verifyUpload = true, type = 'pdf', items = [] }) {
    const _note = this.getMaterialNote(type)
    const result = {
      notUpload: false,
      notUploadDesc: `先将${_note.title}上传到服务器`,
      sizeOver: false,
      sizeOverDesc: `${_note.title}大小超出指定的范围`,
      fileIndex: -1,
      msgType: 'warning'
    }

    if (items.length < 1) {
      result.notUpload = true
      result.notUploadDesc = `请先增加需要上传的${_note.title}`
      return result
    }

    if (verifySize) {
      for (const i in items) {
        const _file = items[i]
        const fileSize = parseInt(_file.size) || 0
        if (fileSize < _note.maxSize) {
          result.sizeOver = false
        } else {
          result.sizeOver = true
          result.fileIndex = i
          break
        }
      }
    }

    if (verifyUpload) {
      for (const i in items) {
        const _file = items[i]
        if (_file.status === 'success') {
          result.notUpload = false
          result.notUploadDesc = `${_note.title}已成功上传`
          result.msgType = 'success'
        } else {
          result.notUpload = true
          result.notUploadDesc = `先将${_note.title}上传到服务器`
          break
        }
      }
    }

    return result
  },
  verifyImage (file) {
    if (!file) {
      return false
    }

    if (!file.type) {
      return false
    }

    if (!file.size) {
      return false
    }

    const _index = ImageTypes.indexOf(file.type)
    let _isImage = false
    if (_index === -1) {
      _isImage = false
    } else {
      _isImage = true
    }

    const isLt2M = file.size / 1024 / 1024 < 2

    if (!_isImage) {
      this.$message.error('上传图片只能是 JPG/PNG/GIF 格式!')
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!')
    }
    return _isImage && isLt2M
  },
  calcLoadType (option, currItems, gainItems, isExtend = true) {
    let _limit = 0

    const _currCount = currItems.length
    const _gainCount = gainItems.length
    let _loadType = LoadTypes.IDLE

    if (isExtend) {
      _limit = parseInt(option.data.limit)
    } else {
      _limit = parseInt(option.limit)
    }

    if (_currCount < 1) {
      _loadType = LoadTypes.EMPTY
    } else {
      if (_gainCount > 0) {
        if (_gainCount < _limit) {
          _loadType = LoadTypes.FINISH
        } else {
          _loadType = LoadTypes.IDLE
        }
      } else {
        _loadType = LoadTypes.FINISH
      }
    }

    return _loadType
  },
  clone (src) {
    if (src) {
      const _dst = {}
      for (const key in src) {
        _dst[key] = src[key]
      }
      return _dst
    } else {
      return src
    }
  },
  deepClone (src) {
    if (src) {
      return JSON.parse(JSON.stringify(src))
    } else {
      return src
    }
  },
  assign (dst, src) {
    for (const key in dst) {
      if (src[key]) {
        dst[key] = src[key]
      }
    }
  },
  jsonToObject (jsonStr) {
    if (!jsonStr) {
      return {}
    }
    let result = {}
    try {
      result = JSON.parse(jsonStr)
    } catch (err) {
      // console.warn('co.api.comm jsonToObject is error, err: %o', err)
      result = {
        value: jsonStr
      }
    }
    return result
  },
  jsonToArray (jsonStr) {
    if (!jsonStr) {
      return []
    }
    let result = []
    try {
      result = JSON.parse(jsonStr)
    } catch (err) {
      console.warn('co.api.comm jsonToArray is error, err: %o', err)
      result = []
    }
    return result
  },
}

export default apiComm
