import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui'
import VueQuillEditor from 'vue-quill-editor'

import './plugins'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'element-ui/packages/theme-chalk/src/index.scss'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import coApi from '@/api/co.api'
import coApp from '@/api/co.app'

Vue.prototype.$init = coApp.init
Vue.prototype.$api = coApi

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VideoPlayer)
Vue.use(VueQuillEditor)
Vue.use(Viewer)

Viewer.setDefaults({
  inline: true,
  button: true,
  navbar: true,
  title: false,
  toolbar: true,
  tooltip: false,
  movable: true,
  zoomable: true,
  rotatable: true,
  scalable: true,
  transition: true,
  fullscreen: true,
  keyboard: true,
})

/**
 * 网站发布路径
 * /usr/share/nginx/html/zuimei_www
*/

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
