
import http from '@/api/api/co.api.http'
import httpx from '@/api/api/co.api.httpx'
import url from '@/api/api/co.api.url'
import comm from '@/api/api/co.api.comm'
import link from '@/api/api/co.api.link'
import page from '@/api/api/co.api.page'
import rapid from '@/api/api/co.api.rapid'
import captcha from '@/api/api/co.api.captcha'

export default {
  http,
  httpx,
  link,
  url,
  comm,
  rapid,
  page,
  captcha
}
