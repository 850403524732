
import base from './co.app.base.js'

const APP_NAME = `${base.prep}-${base.name}`

export default {
  addr_list: `${APP_NAME}_addr_list`,
  addr_default: `${APP_NAME}_addr_default`,
  user_info: `${APP_NAME}_user_info`,
  user_token: `${APP_NAME}_user_token`,
  user_register: `${APP_NAME}_user_register`,
  user_invited: `${APP_NAME}_user_invited`,
  user_enjoy: `${APP_NAME}_user_enjoy`,
  words_info: `${APP_NAME}_words_info`,
  words_goods: `${APP_NAME}_words_goods`,
  words_emall: `${APP_NAME}_words_emall`,
  posi_citys: `${APP_NAME}_posi_citys`,
  posi_lately: `${APP_NAME}_posi_lately`,
  conf_site: `${APP_NAME}_conf_site`,
  dict_site: `${APP_NAME}_dict_site`,
  site_list: `${APP_NAME}_site_list`,
  chan_pay: `${APP_NAME}_chan_pay`,
  chan_login: `${APP_NAME}_chan_login`,
  im_channel: `${APP_NAME}_im_channel`,
  app_config: `${APP_NAME}_app_config`,
  curr_station: `${APP_NAME}_curr_station`,
  subscribe_info: `${APP_NAME}_subscribe_info`,
  user_workplace: `${APP_NAME}_user_workplace`,
  feed_info: `${APP_NAME}_feed_info`,
  activity_info: `${APP_NAME}_activity_info`,
  topic_info: `${APP_NAME}_topic_info`,
  category_info: `${APP_NAME}_category_info`,
  clean_info: `${APP_NAME}_clean_info`,
  posi_hiscitys: `${APP_NAME}_posi_hiscitys`,
  posi_last: `${APP_NAME}_posi_last`,
}
