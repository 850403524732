<template>
  <div
    class="co-flex-row co-items-center"
    :class="[margin]"
    style="cursor: pointer;"
  >
    <v-icon
      :size="size"
      :color="color || 'blue-grey'"
    >
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
  export default {
    name: 'LandIconAlbum',
    props: {
      name: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: 'mdi-folder-plus-outline'
      },
      color: {
        type: String,
        default: 'blue-grey'
      },
      size: {
        type: [Number, String],
        default: 20
      },
      margin: {
        type: String,
        default: 'mr-4'
      }
    },
    methods: {
      onClick () {
        this.$emit('action', {
          name: this.name
        })
      }
    }
  }
</script>
