
import comm from '@/api/api/co.api.comm'
import cookies from '@/api/lib/co.lib.cookie'

const fly = require('flyio')

fly.interceptors.request.use((request) => {
  request.headers.token = cookies.get('token')
  request.headers['Content-Type'] = 'application/json'
  return request
})

fly.interceptors.response.use(
  (response) => {
    const data = response.data
    return data
  },
  (err) => {
    console.warn('fly response is error! err:%o', err)
    const result = {
      code: 0,
      message: '成功'
    }

    result.code = err.status
    switch (err.status) {
      case 1:
        result.message = '网络连接异常'
        break
      case 2:
        result.message = '网络连接超时'
        break
      case 400:
        result.message = '请求错误'
        break
      case 401:
        window.sessionStorage.removeItem('username')
        window.sessionStorage.removeItem('token')
        window.history.go(0)
        result.message = '用户未登录'
        break
      case 403:
        result.message = '拒绝访问'
        break
      case 404:
        result.message = '请求地址出错'
        break
      case 408:
        result.message = '请求超时'
        break
      case 500:
        result.message = '服务器内部错误'
        break
      case 501:
        result.message = '服务未实现'
        break
      case 502:
        result.message = '网关错误'
        break
      case 503:
        result.message = '服务不可用'
        break
      case 504:
        result.message = '网关超时'
        break
      case 505:
        result.message = 'HTTP版本不受支持'
        break
      default:
        result.message = err.message
        break
    }
    return result
  }
)

const apiHttp = {
  fly,
  doResponse (resp, callback) {
    // console.log('land.http doResponse, resp: %o', resp)
    let status = false
    const result = {
      data: null,
      desc: '',
      total: 0
    }

    if (resp) {
      if (resp.code === '0') {
        status = true
        result.data = resp.data
        result.desc = resp.desc
        result.total = parseInt(resp.total, 10)
      } else {
        status = false
        result.data = {
          code: resp.code,
          message: resp.desc
        }
      }
    }

    if (typeof callback === 'function') {
      callback(status, result)
    }
  },
  get (url, param, callback) {
    fly.get(url, param)
      .then((resp) => {
        apiHttp.doResponse(resp, callback)
      }).catch((err) => {
        console.log('http get, url:%o, param:%o', url, param)
        console.warn('fly get is error! err:%o ', err)
        apiHttp.doResponse(null, callback)
      })
  },
  post (url, param, callback) {
    fly.post(url, param)
      .then((resp) => {
        apiHttp.doResponse(resp, callback)
      }).catch((err) => {
        console.log('http post, url:%o, param:%o', url, param)
        console.warn('fly post is error! err:%o ', err)
        apiHttp.doResponse(null, callback)
      })
  },
  postBy (url, param, callback, options = {}) {
    fly.interceptors.request.use((request) => {
      for (const key in options) {
        request.headers[key] = options[key]
      }
      return request
    })
    fly.post(url, param)
      .then((resp) => {
        apiHttp.doResponse(resp, callback)
      }).catch((err) => {
        console.log('http post, url:%o, param:%o', url, param)
        console.warn('fly post is error! err:%o ', err)
        apiHttp.doResponse(null, callback)
      })
  },
  getItems (params) {
    if (!params) {
      console.error('coHttp getItems, params is null!')
      return
    }

    if (!params.items) {
      console.error('coHttp getItems, params.items is null!')
      return
    }

    const callback = function (status, resp) {
      // console.log('coHttp getItems, url:%o ', params.url)
      // console.log('coHttp getItems, status:%o, resp: %o', status, resp)
      const result = {
        status: status,
        originalCount: params.items.length,
        increasedCount: 0,
        loadType: 0,
        total: 0
      }

      if (status) {
        if (params.reload) {
          params.items.splice(0, params.items.length)
        }

        result.total = resp.total
        params.total = resp.total
        if (resp.data) {
          let cnt = 0
          if (typeof params.transform === 'function') {
            cnt = params.transform(params, resp.data)
          } else {
            cnt = 0
            console.warn('coHttp getItems, transform is null!')
          }

          params.loadType = comm.calcLoadType(params, params.items, resp.data)
          params.data.offset = cnt

          result.loadType = params.loadType
          result.increasedCount = resp.data.length
        } else {
          if (params.items.length) {
            result.loadType = comm.LoadTypes.FINISH
          } else {
            result.loadType = comm.LoadTypes.EMPTY
          }
        }
      } else {
        result.loadType = comm.LoadTypes.FAIL
        console.warn('coHttp getItems is error, url:%o ', params.url)
      }

      if (typeof params.executed === 'function') {
        params.executed(result)
      }

      params.reload = false
    }

    if (typeof params.executing === 'function') {
      params.executing()
    }
    apiHttp.get(params.url, params.data, callback)
  },
  getJson (params) {
    if (!params) {
      console.error('co.api.http getJson, params is null!')
      return
    }

    const callback = function (status, resp) {
      console.log('co.api.http getJson, url = %o ', params.url)
      // console.log('co.api.http getJson, status:%o, resp: %o', status, resp)
      const res = {
        status,
        data: {}
      }

      if (status) {
        if (resp) {
          res.data = resp.data
        } // if (resp)
      } else {
        console.warn('co.api.http getJson fail, url = %o ', params.url)
        console.warn('co.api.http getJson fail, status:%o, resp: %o', status, resp)
      } // if (status)

      if (typeof params.executed === 'function') {
        params.executed(res)
      }
    } // const callback = function(status, resp)

    if (typeof params.executing === 'function') {
      params.executing()
    }
    apiHttp.get(params.url, params.data, callback)
  },
  getDetail (params) {
    if (!params) {
      console.error('coHttp getDetail, params is null!')
      return
    }

    const callback = function (status, resp) {
      // console.log('coHttp getDetail, status:%o, resp: %o', status, resp)
      const result = {
        status,
        data: null
      }

      if (status) {
        if (resp.data) {
          if (typeof params.transform === 'function') {
            params.detail = params.transform(resp.data)
            result.data = params.detail
          } else {
            console.warn('coHttp getDetail, transform is null!')
          }
        }
      } else {
        console.warn('coHttp getDetail is error, url:%o ', params.url)
      }

      if (typeof params.executed === 'function') {
        params.executed(result)
      }
    }

    if (typeof params.executing === 'function') {
      params.executing()
    }

    apiHttp.get(params.url, params.data, callback)
  },
  pull ({
    url = '',
    data = null,
    executing = null,
    executed = null
  }) {
    const callback = function (status, resp) {
      // console.log('land.http pull(get), status: %o, resp: %o', status, resp)
      const result = { status, data: {} }

      if (resp) {
        result.data = resp.data || {}
      }

      if (typeof executed === 'function') {
        executed(result)
      }
    }

    if (typeof executing === 'function') {
      executing()
    }

    apiHttp.get(url, data, callback)
  },
  push ({
    url = '',
    data = null,
    executing = null,
    executed = null
  }) {
    const callback = function (status, resp) {
      // console.log('land.http push(post), status: %o, resp: %o', status, resp)
      const result = { status, data: {} }

      if (resp) {
        result.data = resp.data || {}
      }

      if (typeof executed === 'function') {
        executed(result)
      }
    }

    if (typeof executing === 'function') {
      executing()
    }

    apiHttp.post(url, data, callback)
  },
  pushBy ({
    url = '',
    data = null,
    options = {},
    executing = null,
    executed = null
  }) {
    const callback = function (status, resp) {
      // console.log('land.http push(post), status: %o, resp: %o', status, resp)
      const result = { status, data: {} }

      if (resp) {
        result.data = resp.data || {}
      }

      if (typeof executed === 'function') {
        executed(result)
      }
    }

    if (typeof executing === 'function') {
      executing()
    }

    apiHttp.postBy(url, data, callback, options)
  },
  /**
   * 创建数据
   * @param { url } 创建数据接口地址
   * @param { data } 创建数据对象
   * @param { executing } 创建数据前执行事件
   * @param { executed } 创建数据后执行事件
   */
  create ({
    url = '',
    data = null,
    executing = null,
    executed = null
  }) {
    if (!data) {
      comm.showMessage({
        content: '数据为空，不能增加',
        type: comm.MessageTypes.WARNING
      })
      return
    }

    apiHttp.push({
      url, data, executing, executed
    })
  },
  delete ({
    url = '',
    id = '',
    data = null,
    executing = null,
    executed = null
  }) {
    if (!url) {
      comm.showMessage({
        content: 'url为空，不能删除',
        type: comm.MessageTypes.WARNING
      })
      return
    }

    if (!id) {
      comm.showMessage({
        content: 'Id为空，不能删除',
        type: comm.MessageTypes.WARNING
      })
      return
    }

    const href = `${url}/${id}`
    apiHttp.pull({
      url: href, data, executing, executed
    })
  },
  update ({
    url = '',
    id,
    data = null,
    executing = null,
    executed = null
  }) {
    if (!url) {
      comm.showMessage({
        content: 'url为空，不能更新',
        type: comm.MessageTypes.WARNING
      })
      return
    }

    if (!id) {
      comm.showMessage({
        content: 'Id为空，不能更新',
        type: comm.MessageTypes.WARNING
      })
      return
    }

    if (!data) {
      comm.showMessage({
        content: '数据为空，不能更新',
        type: comm.MessageTypes.WARNING
      })
      return
    }

    const href = `${url}/${id}`
    apiHttp.push({
      url: href, data, executing, executed
    })
  }
}

export default apiHttp
