var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('land-info-card',{attrs:{"title":_vm.title}},[_c('div',{class:['d-flex', 'flex-column', _vm.backColor, 'mt-6']},_vm._l((_vm.items),function(item,index){return _c('v-hover',{key:("cate_" + index),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:[
          'px-4 py-2', 'text-subtitle-1',
          'rounded-lg', 'rounded-r-0',
          index === _vm.selectedItem ? _vm.activeColor : (hover ? _vm.hoverColor : 'transparent'),
          index === _vm.selectedItem ? 'font-weight-bold' : '',
          index === _vm.selectedItem ? '' : ''
        ],on:{"click":function($event){return _vm.onClick(item, index)}}},[_c('a',{staticClass:"black--text darken-4"},[_vm._v(_vm._s(item.title))])])]}}],null,true)})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }