
import api from '@/api/co.api'

import comm from '@/api/socket/co.socket.comm'
import socketTasker from '@/api/socket/co.socket.tasker'

/*
* receiver 消息接收器，处理接收到的信息
*/
const Receiver = {
  handlers: {},
  events: {},
  bind ({ name, trigger }) {
    if (!name) {
      return
    }

    Receiver.events[name] = {
      name,
      trigger
    }
  },
  unbind (name) {
    if (!name) {
      return
    }
    const item = Receiver.events[name]
    if (item) {
      Receiver.events[name] = undefined
    }
  },
  triggerEvent (param) {
    for (const key in Receiver.events) {
      const item = Receiver.events[key]
      if (item) {
        if (typeof item.trigger === 'function') {
          item.trigger(param)
        }
      }
    }
  },
  reply ({
    messageId,
    status = comm.States.RECEIVED
  }) {
    if (socketTasker.opened) {
      const data = JSON.stringify({ messageId, status })
      socketTasker.handler.send(data)
    }
  },
  toResponse (cacher, res) {
    console.info('co.socket receiver toResponse, res: %o', res)
  },
  toEnsure (cacher, res) {
    console.info('co.socket receiver toEnsure, res: %o', res)
  },
  toGroup (cacher, res) {
    console.info('co.socket.receiver toGroup, res: %o', res)
    const sessId = `group_${res.destId}`

    let session = cacher.chats[sessId]

    if (session) {
      session.newest += 1
    } else {
      session = {
        ...res.dester,
        userId: `${res.destId}`,
        sessId,
        newest: 1
      }
      cacher.chats[sessId] = session
    }

    Receiver.reply({
      messageId: res.messageId
    })

    const param = {
      session,
      message: api.comm.deepClone(res)
    }
    Receiver.triggerEvent(param)
  },
  toPrivate (cacher, res) {
    console.info('co.socket.receiver toPrivate, res: %o', res)
    const sessId = `user_${res.senderId}`

    let session = cacher.chats[sessId]

    if (session) {
      session.newest += 1
    } else {
      session = {
        ...res.sender,
        userId: `${res.senderId}`,
        sessId,
        newest: 1
      }
      cacher.chats[sessId] = session
    }

    Receiver.reply({
      messageId: res.messageId
    })

    const param = {
      session,
      message: api.comm.deepClone(res)
    }
    Receiver.triggerEvent(param)
  }
}

export default Receiver
