
const appIcon = {
  forward: 'mdi-share-outline',
  forward_fill: 'mdi-share',
  comment: 'mdi-comment-processing-outline',
  like: 'mdi-thumb-up-outline',
  like_fill: 'mdi-thumb-up',
  collect: 'mdi-star-outline',
  collect_fill: 'mdi-star',
  album: 'mdi-folder-plus-outline',
  attention_plus: 'mdi-account-multiple-plus-outline',
  attention_minus: 'mdi-account-multiple-minus-outline',
  sheild: 'mdi-eye-off-outline',
  view_public: 'mdi-eye-outline',
  view_private: 'mdi-eye-lock-outline',
  view_fans: 'mdi-eye-check-outline',
  view_attention: 'mdi-eye-plus-outline',
  arrow_right: 'mdi-chevron-right',
  arrow_down: 'mdi-chevron-down',
  plus: 'mdi-plus'
}

export default appIcon
