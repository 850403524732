<template>
  <v-theme-provider dark>
    <section id="land-hero-alt">
      <land-img
        :height="wdHeight"
        :gradient="gradient"
        :src="cover"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <land-heading
              :title="title"
              space="3"
              weight="500"
              align="center"
            />

            <land-divider
              v-if="divisible"
              :color="assistColor"
              align="center"
              space="8"
            />

            <v-breadcrumbs
              v-if="items && items.length"
              :items="items"
              class="justify-center pa-0"
              divider=">"
              large
            />
          </v-col>
        </v-row>
      </land-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'LandHeroAlt',
    props: {
      title: {
        type: String,
        default: ''
      },
      cover: {
        type: String,
        default: ''
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      divisible: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        points: {
          xs: 260,
          sm: 320,
          md: 420,
          lg: 420,
          xl: 420
        },
      }
    },
    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.secondary}66`
        const color2 = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))
        return `to top right, ${overlay1}, ${overlay2}`
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || '420'
        return ht
      },
    },
  }
</script>

<style lang="sass">
  #land-hero-alt
    .v-breadcrumbs__item
      color: #FFFFFF
</style>
