
import socketMain from '@/api/socket/co.socket.main'

export default {
  comm: socketMain.comm,
  cacher: socketMain.cacher,
  receiver: socketMain.receiver,
  tasker: socketMain.tasker,
  main: socketMain
}
