
const apiLink = {
  home: {
    path: '/',
    name: 'Home',
    title: '首页'
  },
  activity: {
    name: 'Activity',
    title: '活动'
  },
  hot: {
    name: 'Hot',
    title: '热门'
  },
  circle: {
    name: 'Circle',
    title: '圈子'
  },
  about: {
    name: 'About',
    title: '联系我们'
  },
  my: {
    path: '/my',
    name: 'My',
    title: '个人中心'
  },
  login: {
    path: '/my/login',
    name: 'Login',
    title: '登录'
  },
  album: {
    path: '/my/album',
    name: 'Album',
    title: '我的影集'
  },
  certificate: {
    path: '/my/certificate',
    name: 'Certificate',
    title: '个人信息'
  },
  info: {
    path: '/my/info',
    name: 'Info',
    title: '个人设置'
  },
  messages: {
    path: '/my/messages',
    name: 'Messages',
    title: '消息中心'
  },
  homepage: {
    path: '/user/homepage',
    name: 'Homepage',
    title: '个人主页'
  },
  upload: {
    name: 'Upload',
    title: '上传'
  },
  issueVideo: {
    name: 'IssueVideo',
    title: '发表视频'
  },
  issueArticle: {
    name: 'IssueArticle',
    title: '文章'
  },
  pound: {
    path: '/home/pound',
    name: 'Pound',
    title: '话题'
  },
  search: {
    path: '/home/search',
    name: 'Search',
    title: '查询'
  },
  hotList: {
    path: '/hot/list',
    name: 'HotList',
    title: '热门列表'
  },
  hotDetail: {
    path: '/hot/detail',
    name: 'HotDetail',
    title: '热门详情'
  },
  activityDetail: {
    path: '/activity/detail',
    name: 'ActivityDetail',
    title: '活动详情'
  },
  activityEnroll: {
    path: '/activity/enroll',
    name: 'ActivityEnroll',
    title: '参赛作品'
  },
  activityPutin: {
    path: '/activity/putin',
    name: 'ActivityPutin',
    title: '上传参赛作品'
  },
}

export default apiLink
