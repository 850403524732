
import base from '@/api/app/co.app.base'

const appInit = {
  name: base.name,
  title: base.title,
  version: base.version,

  // token超时偏移量 毫秒
  expireOffset: 1000,
  autoLogin: 360000000,

  defaultAvatar: 'https://resources.huishuapp.com/27676252733833216.jpeg',
  copyright: '南京索正企业管理咨询有限公司',
  beianId: '苏ICP备2023012163号-1',
  authTime: '©2023年 ~ 2024年',

  reset (data) {
    const me = appInit
    if (data) {
      console.log('#co.app.init reset, data is null!')
      return
    }
    const obj = data.init || {}
    for (const key in obj) {
      me[key] = obj[key] || ''
    }
  }
}

export default appInit
