
const socketCacher = {
  chats: {},
  reset (chat) {
    const result = socketCacher.chats[chat.type]
    if (result) {
      result.newest = 0
    }
  },
  resetBy (type, userId = '') {
    const chat = socketCacher.chats[type]
    if (chat) {
      if (type === 'chat') {
        const sessId = `sess_${userId}`
        const item = chat.items[sessId]
        if (item) {
          item.newest = 0
        }
      } else {
        chat.newest = 0
      }
    }
  },
  getLast (type, userId = '') {
    const result = {
      newest: 0
    }
    const chat = socketCacher.chats[type]
    if (chat) {
      if (type === 'chat') {
        const sessId = `sess_${userId}`
        const item = chat.items[sessId]
        // console.log('getNewest item: %o', item)
        if (item) {
          result.userId = item.userId
          result.newest = item.newest || 0
          result.message = item.message
          result.hint = item.hint
        }
      } else {
        result.newest = chat.newest
      }
    }
    return result
  },
  getChat (msg) {
    let result = null
    result = socketCacher.chats[msg.type]
    if (!result) {
      result = {
        type: msg.type || msg.contentType,
        newest: 0,
        hint: '',
        items: {}
      }

      socketCacher.chats[msg.type] = result
    }

    return result
  },
  getChatBy (type) {
    return socketCacher.chats[type] || {}
  },
  getSession (chat, msg) {
    const sessId = `sess_${msg.from.userId}`
    let result = chat.items[sessId]
    if (!result) {
      result = {
        userId: '',
        message: '',
        hint: '',
        newest: 0
      }
      chat.items[sessId] = result
    }

    return result
  },
  add (msg) {
    const chat = socketCacher.getChat(msg)
    chat.newest += 1
    chat.hint = msg.hintTime
    if (msg.type === 'chat') {
      const sess = socketCacher.getSession(chat, msg)
      sess.userId = msg.from.userId
      sess.message = msg.content
      sess.hint = msg.hintTime
      sess.newest += 1
    }

    // console.log('socketCacher chats: %o', socketCacher.chats)
  }
}

export default socketCacher
