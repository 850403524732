<template>
  <v-theme-provider dark>
    <section id="land-intro-image">
      <land-img
        :height="wdHeight"
        :gradient="gradient"
        :src="cover"
        flat
        max-width="100%"
        tile
      >
        <div
          class="d-flex flex-column justify-space-around px-4 px-md-16 py-6 py-md-14"
          :class="[classify.align ? `align-${classify.align}` : 'align-center']"
          style="height: 100%"
        >
          <div
            class="d-flex flex-column"
            :class="[classify.align ? `align-${classify.align}` : 'align-center']"
          >
            <!-- <div
              v-if="content.title"
              class="text-h5 text-sm-h4 text-md-h3"
              :class="[classify.color ? `${classify.color}--text` : 'white--text']"
              v-text="content.title"
            /> -->
            <land-subheading
              v-if="header.title"
              :title="header.title"
              class="text-uppercase"
              :space="smAndDown ? 2 : 4"
            />
            <div
              v-if="header.english"
              class="text-h6"
              :class="[
                classify.color ? `${classify.color}--text` : 'white--text',
                smAndDown ? 'mb-1' : 'mb-3'
              ]"
              style="font-weight: 300;"
              v-text="header.english"
            />
            <land-divider
              v-if="divisible"
              :class="[classify.align === 'center' ? 'mx-auto' : '']"
              color="white"
              width="28"
            />

            <div
              v-if="header.subtitle"
              class="text-body-1 text-md-h6 text-center"
              :class="[
                classify.color ? `${classify.color}--text` : 'white--text',
                smAndDown ? 'mt-3' : 'mt-6'
              ]"
              style="font-weight: 300;"
              v-html="header.subtitle"
            />

            <v-btn
              v-if="button.text"
              depressed
              :color="button.backColor || 'primary'"
              :class="[button.foreColor ? `${button.foreColor}--text` : 'white--text', 'mt-5']"
              :width="btnWidth"
              @click="onClick"
            >
              {{ button.text }}
            </v-btn>
          </div>
        </div>
      </land-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'LandIntroImage',
    props: {
      cover: {
        type: String,
        default: ''
      },
      classify: {
        type: Object,
        default: () => {
          return {}
        }
      },
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
      divisible: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        points: {
          xs: 260,
          sm: 320,
          md: 420,
          lg: 420,
          xl: 420
        },
      }
    },
    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.secondary}11`
        const color2 = `${this.$vuetify.theme.themes.light.secondary}33`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))
        return `to top right, ${overlay1}, ${overlay2}`
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const pts = this.classify.points || {}
        const ht = pts[name] || this.points[name] || 420
        return ht
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 120
        } else {
          return 140
        }
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    methods: {
      onClick (ev) {
        const params = {
          header: this.header
        }

        this.$emit('click', params)
      },
    }
  }
</script>

<style lang="sass">
</style>
