<template>
  <el-popover
    :ref="`fwd_${name}`"
    :disabled="mode !== 'popover'"
    :placement="placement"
    width="400"
    trigger="click"
    :title="title"
  >
    <div class="co-flex-col">
      <el-input
        v-model="content"
        placeholder="转发后显示的标题"
        maxlength="64"
        show-word-limit
        required
        :rules="rules"
      />
      <div class="co-flex-col co-justify-end co-items-end co-mt4">
        <el-button
          size="medium"
          type="primary"
          @click="onForward"
        >
          转发
        </el-button>
      </div>
    </div>
    <div
      slot="reference"
      class="co-flex-row co-items-center"
      :class="[margin]"
      style="cursor: pointer;"
      @click="onClick"
    >
      <v-icon
        :size="size"
        :color="color || 'blue-grey'"
      >
        {{ icon }}
      </v-icon>
      <span
        class="text-body-1 ml-1"
        :class="[color ? `${color}--text` : 'blue-grey--text']"
      >
        {{ value || 0 }}
      </span>
    </div>
  </el-popover>
</template>

<script>
  export default {
    name: 'LandIconForward',
    props: {
      name: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: 'mdi-share-outline'
      },
      value: {
        type: [Number, String],
        default: ''
      },
      color: {
        type: String,
        default: 'blue-grey'
      },
      size: {
        type: [Number, String],
        default: 20
      },
      margin: {
        type: String,
        default: 'mr-4'
      },
      placement: {
        type: String,
        default: 'bottom'
      },
      contentTitle: {
        type: String,
        default: ''
      },
      mode: {
        type: String,
        default: 'popover'
      }
    },
    data () {
      return {
        title: '内容转发',
        content: '',
        rules: [
          v => !!v || '必须填写标题',
          v => v.length <= 64 || '标题不能大于64个字符',
        ],
        dlg: {
          title: '',
          visible: false,
        }
      }
    },
    methods: {
      onForward () {
        const name = `fwd_${this.name}`
        const frm = this.$refs[name]

        if (frm) {
          frm.doClose()
        }

        const params = {
          name: this.name,
          content: this.content
        }
        this.$emit('action', params)
      },
      onClick () {
        if (this.mode === 'popover') {
          return
        }
        const params = {
          name: this.name,
        }
        this.$emit('trigger', params)
      },
      onDialogForward () {
        this.dlg.visible = false
        const params = {
          name: this.name,
          content: this.content
        }
        this.$emit('action', params)
      }
    }
  }
</script>
