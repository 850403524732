<template>
  <v-carousel
    cycle
    :interval="interval"
    :height="wdHeight"
    :hide-delimiters="hideDelimiters"
    :show-arrows="showArrows"
    show-arrows-on-hover
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(item, index) in items"
      :key="index"
    >
      <v-img
        height="100%"
        :src="item.cover"
        @click="onClick(item, index)"
      >
        <div class="co-flex-col co-justify-between co-h-full">
          <div class="co-flex-col co-justify-center co-items-end co-flex-grow pa-16">
            <v-btn
              v-if="button.text && index === 0"
              depressed
              :color="button.backColor || 'primary'"
              :class="[button.foreColor ? `${button.foreColor}--text` : 'white--text', 'mr-6']"
              :width="smAndDown ? 90 : 180"
              :height="smAndDown ? 36 : 50"
              @click="onAction(button)"
            >
              {{ button.text }}
            </v-btn>
          </div>
          <div
            v-if="item.coverName || item.coverAuthor"
            class="co-flex-row co-justify-end co-w-full co-h32 px-10"
          >
            <span class="co-text-2xs white--text">
              {{ item.coverName }}
              <span
                v-if="item.coverAuthor"
                class="px-1"
              >by</span>
              {{ item.coverAuthor }}
            </span>
          </div>
        </div>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    name: 'LandBanner',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      height: {
        type: [Number, String],
        default: 500
      },
      points: {
        type: Object,
        default: () => {
          return {
            xs: 200,
            sm: 300,
            md: 500,
            lg: 650,
            xl: 800
          }
        }
      },
      cycle: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 5000
      },
      hideDelimiters: {
        type: Boolean,
        default: false
      },
      showArrows: {
        type: Boolean,
        default: true
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {}
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || this.height
        return ht
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    methods: {
      onAction (btn) {
        this.$emit('action', btn)
      },
      onClick (item, index) {
        const params = {
          index,
          item
        }

        this.$emit('click', params)
      }
    }
  }
</script>

<style>
</style>
