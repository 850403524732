
import api from '@/api/co.api'
import cache from '@/api/config/co.config.cache'
import parser from '@/api/config/co.config.parser'
import comm from '@/api/config/co.config.comm'
import main from '@/api/config/co.config.main'

const coConfig = {
  comm,
  parser,
  cache,
  main,
  initialize ({
    siteId = '',
    type = '',
  }) {
    cache.extract()
    // this.getConfigs({
    //   siteId,
    //   type
    // }).then((res) => {
    //   console.info('co.config getConfigs is ok!!!')
    // })
  },
  getConfigs ({
    siteId = '',
    type = ''
  }) {
    const me = this

    const prom = new Promise((resolve, reject) => {
      const executed = function (ev) {
        // console.log('co.config getConfigs, cache.items: %o', cache.items)
        if (ev.status) {
          me.parseConfigs()
          resolve({ status: ev.status, data: cache.data })
        } else {
          reject(new Error('获取配置失败'))
        }
      }

      cache.params = main.getParams({ siteId, type, executed })
      cache.items = cache.params.items
      cache.params.reload = true

      api.http.getItems(cache.params)
    })
    return prom
  },
  parseConfigs () {
    for (const i in cache.items) {
      const item = cache.items[i]
      const trigger = parser[item.value] || parser.preset
      if (typeof trigger === 'function') {
        trigger(item)
      }
    }

    cache.store(cache.data)
  },
  getConfig (key) {
    // console.log('co.config getConfig, key: %o', key)
    let conf = null
    try {
      conf = cache.data[key]
      if (!conf) {
        conf = cache.cache[key] || {}
      } else {
        const keys = Object.keys(conf)
        if (keys.length < 1) {
          conf = cache.cache[key] || {}
        }
      }
    } catch (error) {
      conf = {}
      console.warn('co.config getConfig, error: %o', error)
    }

    return conf
  },
  getSiteParam (key) {
    const data = this.getConfig(comm.Keys.site)
    const preset = comm.sitePreset[key]
    let icon = ''

    if (preset) {
      icon = preset.icon || ''
    }

    let param = null
    if (data) {
      param = data[key] || preset
      param.icon = icon
    }
    param = param || {}
    return param
  },
}

export default coConfig
