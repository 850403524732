
const socketTasker = {
  handler: undefined,
  opened: false,
  channelId: '',
  userId: '',
  dispatchers: {}
}

export default socketTasker
