
import api from '@/api/co.api.js'
import app from '@/api/co.app.js'

import info from '@/api/user/co.user.info.js'
import local from '@/api/lib/co.lib.local'

const userToken = {
  cache: api.comm.jsonToObject(local.get(app.keys.user_token)),
  get () {
    const val = local.get(app.keys.user_token) || ''
    return api.comm.jsonToObject(val)
  },
  set (data = {}) {
    for (const key in data) {
      const val = data[key]
      if (val) {
        userToken.cache[key] = val
      }
    }

    local.set(app.keys.user_token, JSON.stringify(userToken.cache))
  },
  save (data, callback = null) {
    const trigger = function (resp) {
      if (typeof callback === 'function') {
        callback(resp)
      }
    }

    const executed = function (res) {
      // console.info('co.user.token gain user, res: %o', res)
      const result = {
        status: res.status,
        data: res.data
      }
      if (res.status) {
        info.save(res.data)
      }

      trigger(result)
    }

    Object.assign(userToken.cache, data)

    local.set(app.keys.user_token, JSON.stringify(data))

    info.gain({
      executed
    })
  },
  // 更新用户Token
  update (token, callback) {
    const executed = function (res) {
      if (res.status) {
        userToken.save(res.data)
      } else {
        // console.warn('co.user.login update token fail! res: %o', res)
        userToken.remove()
      }

      if (typeof callback === 'function') {
        callback({
          status: res.status,
          data: info.cache
        })
      }
    }

    api.httpx.pull({
      url: api.url.web_sso_refresh,
      data: {
        refreshToken: token.refreshToken
      },
      executed
    })

    return true
  },
  refresh () {
    // const token = userToken.get()
    // console.log('token: %o', token)
    // if (token.refreshToken) {
    //   userToken.update(token)
    // }
  },
  remove () {
    local.remove(app.keys.user_token)
  }
}

export default userToken
