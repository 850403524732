
import comm from '@/api/user/co.user.comm'
import info from '@/api/user/co.user.info'
import login from '@/api/user/co.user.login'
import token from '@/api/user/co.user.token'

const coUser = {
  comm,
  info,
  login,
  token
}

export default coUser
