
const appEvent = {
  USER_LOGIN: 'userLogin',
  USER_LOGOUT: 'userLogout',
  USER_CHANGE: 'userChange',
  USER_INFO_CHANGE: 'userInfoChange',
  LOGIN_PHONE: 'loginPhone',
  LOGIN_QRCODE: 'loginQrcode',
  SOCKET_OPEN: 'socketOpen',
  SOCKET_ERROR: 'socketError',
  SOCKET_CLOSE: 'socketClose',
  SOCKET_NOTIN: 'socketNotIn',
  SEND_SUCCESS: 'sendSuccess',
  SEND_FAIL: 'sendFail',
  USER_COVER_CHANGE: 'user_cover_change',
  USER_COVER_CHOOSE: 'user_cover_choose',
  MY_WORKS_GET: 'my_works_get',
  MY_ALBUMS_GET: 'my_albums_get',
  MY_VIDEOS_GET: 'my_videos_get',
  MY_ARTICLES_GET: 'my_articles_get',
  MY_ACTIVITYS_GET: 'my_activitys_get',
  USER_WORKS_GET: 'USER_works_get',
  USER_ALBUMS_GET: 'USER_albums_get',
  USER_VIDEOS_GET: 'USER_videos_get',
  USER_ARTICLES_GET: 'USER_articles_get',
  ALBUM_COVER_CHANGE: 'album_cover_change',
  ALBUM_INFO_CHANGE: 'album_info_change',
  CONTENT_REMOVE: 'content_remove',
  CONTENT_CHANGE: 'content_change',
  HOME_CHECK_LOGIN: 'home_check_login',
  POUND_REFRESH: 'pound_refresh'
}

export default appEvent
