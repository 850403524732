<template>
  <div
    v-show="ready"
    class="el-carousel__item"
    :class="{
      'is-active': active,
      'el-carousel__item--card': $parent.type === 'card',
      'is-in-stage': inStage,
      'specialIndex': specialIndex,
      'is-hover': hover,
      'is-animating': animating
    }"
    :style="itemStyle"
    @click="handleItemClick"
  >
    <div
      v-if="$parent.type === 'card'"
      v-show="!active"
      class="el-carousel__mask"
    />
    <slot />
  </div>
</template>

<script>
  import { autoprefixer } from 'element-ui/src/utils/util'
  // const CARD_SCALE = 0.83

  export default {
    name: 'ElCarouselItem',

    props: {
      name: String,
      label: {
        type: [String, Number],
        default: ''
      }
    },
    data () {
      return {
        hover: false,
        translate: 0,
        scale: 1,
        active: false,
        ready: false,
        inStage: false,
        specialIndex: false,
        animating: false,
        imgWd: 600,
        imgHt: 400
      }
    },
    computed: {
      parentType () {
        return this.$parent.type
      },
      parentDirection () {
        return this.$parent.direction
      },
      itemStyle () {
        const translateType = this.parentDirection === 'vertical' ? 'translateY' : 'translateX'
        const value = `${translateType}(${ this.translate }px) scale(${ this.scale })`
        // console.log('value: %o', value)
        const style = {
          transform: value
        }
        return autoprefixer(style)
      }
    },
    created () {
      this.$parent && this.$parent.updateItems()
    },
    destroyed () {
      this.$parent && this.$parent.updateItems()
    },
    methods: {
      processIndex (index, activeIndex, length) {
        if (activeIndex === 0) {
          return index === 1 ? 1 : index === 2 ? 2 : index === 3 ? -2 : index === 4 ? -1 : 0
        }

        if (activeIndex === 1) {
          return index === 2 ? 1 : index === 3 ? 2 : index === 4 ? -2 : index === 0 ? -1 : 0
        }

        if (activeIndex === 2) {
          return index === 3 ? 1 : index === 4 ? 2 : index === 0 ? -2 : index === 1 ? -1 : 0
        }

        if (activeIndex === 3) {
          return index === 4 ? 1 : index === 0 ? 2 : index === 1 ? -2 : index === 2 ? -1 : 0
        }

        if (activeIndex === 4) {
          return index === 0 ? 1 : index === 1 ? 2 : index === 2 ? -2 : index === 3 ? -1 : 0
        }
      },

      calcCardTranslate (index, activeIndex, scale) {
        const parentWidth = this.$parent.$el.offsetWidth
        let elWidth = this.$el.offsetWidth
        // console.log('index=%o, elWidth=%o', index, elWidth)
        if (elWidth < 10) {
          elWidth = 600
        }
        // console.log('parentWidth=%o, elWidth=%o', parentWidth, elWidth)
        let trans = 0
        if (index === 0) {
          trans = (parentWidth - elWidth) / 2
        } else if (index === 1) {
          trans = parentWidth - elWidth * scale - (elWidth / 3)
        } else if (index === 2) {
          trans = parentWidth - elWidth * scale
        } else if (index === -1) {
          trans = elWidth * scale / 5
        } else if (index === -2) {
          trans = -(elWidth * scale / 4)
        }

        return trans
      },

      calcTranslate (index, activeIndex, isVertical) {
        const distance = this.$parent.$el[isVertical ? 'offsetHeight' : 'offsetWidth']
        return distance * (index - activeIndex)
      },

      translateItem (index, activeIndex, oldIndex) {
        const parentType = this.$parent.type
        const parentDirection = this.parentDirection
        const length = this.$parent.items.length

        if (parentType !== 'card' && oldIndex !== undefined) {
          this.animating = index === activeIndex || index === oldIndex
        }

        index = this.processIndex(index, activeIndex, length)

        if (parentType === 'card') {
          if (parentDirection === 'vertical') {
            console.warn('[Element Warn][Carousel]vertical direction is not supported in card mode')
          }

          this.inStage = Math.round(Math.abs(index)) <= 1
          this.specialIndex = Math.round(Math.abs(index)) >= 3

          this.active = index === 0
          this.scale = Math.abs(index) === 0 ? 1 : Math.abs(index) === 1 ? 0.85 : Math.abs(index) === 2 ? 0.74 : 0.62
          this.translate = this.calcCardTranslate(index, activeIndex, this.scale)
          // console.log('index=%o, translate=%o, scale=%o', index, this.translate, this.scale)
        } else {
          this.active = index === activeIndex
          const isVertical = parentDirection === 'vertical'
          this.translate = this.calcTranslate(index, activeIndex, isVertical)
        }
        this.ready = true
      },

      handleItemClick () {
        const parent = this.$parent
        if (parent && parent.type === 'card') {
          const index = parent.items.indexOf(this)
          parent.setActiveItem(index)
        }
      }
    },
  }
</script>

<style scoped>
  .el-carousel__item {
    cursor: pointer;
    z-index: 1 !important;
  }
  .el-carousel__item--card.is-in-stage {
    z-index: 2 !important;
  }
  .el-carousel__item--card.is-active {
    z-index: 3 !important;
  }
  .specialIndex{
    z-index: 0 !important;
  }
</style>
