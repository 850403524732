
const Types = {
  // 私聊
  SINGLE: 'private',
  // 群聊
  GROUP: 'group',
  // 通知
  NOTICE: 'notice',
  // 信息发送成功后服务器响应
  RESPONSE: 'response',
  // 信息状态验证响应
  ENSURE: 'ensure'
}

const Events = {
  SOCKET_OPEN: 'socketOpen',
  SOCKET_ERROR: 'socketError',
  SOCKET_CLOSE: 'socketClose',
  SOCKET_NOTIN: 'socketNotIn',
  SEND_SUCCESS: 'sendSuccess',
  SEND_FAIL: 'sendFail',
  ANY_MESSAGE: 'anyMessage'
}

const States = {
  // submiting 本地内容提交中
  SUBMITING: 'submiting',
  // submitFail 本地内容提交失败
  SUBMITFAIL: 'submitFail',
  // submitOk 本地内容提交成功
  SUBMITOK: 'submitOk',
  // unsend 信息成功提交，暂时未发送到对方
  UNSEND: 'unsend',
  // unread 对方未读信息
  UNREAD: 'unread',
  READ: 'read',
  EXPIRED: 'expired',
  // send 信息提交已发送到对方
  SEND: 'send',
  RECEIVED: 'received'
}

const socketComm = {
  Types,
  Events,
  States
}

export default socketComm
