var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:[_vm.margin],attrs:{"no-gutters":false}},_vm._l((_vm.items),function(item,i){return _c('v-col',{key:("gallery_" + i),staticClass:"land-gallery",attrs:{"cols":"12","sm":_vm.smCols,"md":_vm.mdCols}},[_c('v-hover',{attrs:{"disabled":!_vm.hovered,"close-delay":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:[_vm.round, _vm.padding, 'transition-swing',
                 _vm.scaled && hover ? 'land-img-scale' : ''
        ],attrs:{"elevation":hover ? _vm.hoverElevation : _vm.normalElevation,"color":_vm.backColor,"flat":"","tile":""}},[(item.cover)?_c('v-img',{attrs:{"aspect-ratio":_vm.ratio,"src":item.cover,"height":"inherit"}},[_c('div',{staticClass:"co-flex-col co-justify-between co-h-full"},[_c('div',{staticClass:"co-flex-col co-w-full co-flex-grow ",on:{"click":function($event){return _vm.onClick(item, i)}}}),(_vm.tooled)?_c('div',{staticClass:"co-flex-row co-justify-between co-items-center grey darken-4 co-h40 px-3",staticStyle:{"opacity":"0.70"}},[(item.user && item.user.userId)?[_c('div',{staticClass:"d-flex flex-row align-center"},[(item.user.avatar)?_c('v-avatar',{attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":item.user.avatar}})],1):_vm._e(),_c('span',{staticClass:"text-body-2 white--text pl-2"},[_vm._v(_vm._s(item.user.username))])],1)]:_vm._e(),_c('div',{staticClass:"co-flex-row co-items-center"},[(_vm.forwarded)?_c('land-icon-forward',{attrs:{"name":"forward","color":"white","size":"18","margin":"ml-4","value":item.forwardedNum},on:{"action":function($event){return _vm.onAction($event, item, i)}}}):_vm._e(),(_vm.liked)?_c('land-icon-like',{attrs:{"name":"like","color":"white","size":"18","margin":"ml-4","value":item.likesNum},on:{"action":function($event){return _vm.onAction($event, item, i)}}}):_vm._e(),(_vm.albumed)?_c('land-icon-album',{attrs:{"name":"album","size":"18","color":"white","margin":"ml-4"},on:{"action":function($event){return _vm.onAction($event, item, i)}}}):_vm._e()],1)],2):_vm._e()])]):_vm._e(),(_vm.titled && item.title)?_c('div',{class:[
            'text-uppercase', 'co-text-sm',
            'px-4', 'py-2', _vm.textAlign, 'co-text-line1'
          ],staticStyle:{"line-height":"32px"},on:{"click":function($event){return _vm.onClick(item, i)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)]}}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }