// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/pages/landcoo/Home.vue'),
        },
        {
          path: 'attention',
          name: 'Attention',
          component: () => import('@/pages/landcoo/Attention.vue'),
        },
        {
          path: 'hot',
          name: 'Hot',
          component: () => import('@/pages/landcoo/Hot.vue'),
        },
        {
          path: 'activity',
          name: 'Activity',
          component: () => import('@/pages/landcoo/Activity.vue'),
        },
        {
          path: 'circle',
          name: 'Circle',
          component: () => import('@/pages/landcoo/Circle.vue'),
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/pages/landcoo/About.vue'),
          meta: { dark: true },
        },
        {
          path: 'my',
          name: 'My',
          component: () => import('@/pages/landcoo/My.vue'),
        },
        {
          path: 'home/pound',
          name: 'Pound',
          component: () => import('@/pages/landcoo/home/Pound.vue'),
        },
        {
          path: 'home/search',
          name: 'Search',
          component: () => import('@/pages/landcoo/home/Search.vue'),
        },
        {
          path: 'my/album',
          name: 'Album',
          component: () => import('@/pages/landcoo/my/Album.vue'),
        },
        {
          path: 'my/certificate',
          name: 'Certificate',
          component: () => import('@/pages/landcoo/my/Certificate.vue'),
        },
        {
          path: 'my/info',
          name: 'Info',
          component: () => import('@/pages/landcoo/my/Info.vue'),
        },
        {
          path: 'my/login',
          name: 'Login',
          component: () => import('@/pages/landcoo/my/Login.vue'),
        },
        {
          path: 'my/messages',
          name: 'Messages',
          component: () => import('@/pages/landcoo/my/Messages.vue'),
        },
        {
          path: 'my/upload',
          name: 'Upload',
          component: () => import('@/pages/landcoo/my/Upload.vue'),
        },
        {
          path: 'my/issueVideo',
          name: 'IssueVideo',
          component: () => import('@/pages/landcoo/my/IssueVideo.vue'),
        },
        {
          path: 'my/issueArticle',
          name: 'IssueArticle',
          component: () => import('@/pages/landcoo/my/IssueArticle.vue'),
        },
        {
          path: 'user/Homepage',
          name: 'Homepage',
          component: () => import('@/pages/landcoo/user/Homepage.vue'),
        },
        {
          path: 'hot/detail',
          name: 'HotDetail',
          component: () => import('@/pages/landcoo/hot/Detail.vue'),
        },
        {
          path: 'activity/detail',
          name: 'ActivityDetail',
          component: () => import('@/pages/landcoo/activity/Detail.vue'),
        },
        {
          path: 'activity/enroll',
          name: 'ActivityEnroll',
          component: () => import('@/pages/landcoo/activity/Enroll.vue'),
        },
        {
          path: 'activity/putin',
          name: 'ActivityPutin',
          component: () => import('@/pages/landcoo/activity/Putin.vue'),
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
