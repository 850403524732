var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}],staticClass:"el-carousel__item",class:{
    'is-active': _vm.active,
    'el-carousel__item--card': _vm.$parent.type === 'card',
    'is-in-stage': _vm.inStage,
    'specialIndex': _vm.specialIndex,
    'is-hover': _vm.hover,
    'is-animating': _vm.animating
  },style:(_vm.itemStyle),on:{"click":_vm.handleItemClick}},[(_vm.$parent.type === 'card')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.active),expression:"!active"}],staticClass:"el-carousel__mask"}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }