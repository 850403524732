
import link from '@/api/api/co.api.link'
import cookie from '@/api/lib/co.lib.cookie'

const apiPage = {
  navigate (that, name, params = null) {
    const obj = link[name] || {}
    const key = obj.name
    if (!key) {
      console.log('###name is not exists! name=%o', key)
      return
    }

    if (!that) {
      console.log('###this is null!!!')
      return
    }

    if (params) {
      cookie.set(key, JSON.stringify(params))
    }

    that.$router.push({ name: key })
  },
  navigateBy (that, name, params = null) {
    const obj = link[name] || {}
    const key = obj.name
    if (!key) {
      console.log('###name is not exists! name=%o', key)
      return
    }

    if (!that) {
      console.log('###this is null!!!')
      return
    }

    let path = obj.path
    if (params) {
      const arr = []
      for (const key in params) {
        arr.push(`${key}=${params[key]}`)
      }
      if (arr.length) {
        path = `${path}?${arr.join('&')}`
      }
    }

    that.$router.push({ path })
  },
  replace (that, name, params = null) {
    const obj = link[name] || {}
    const key = obj.name
    if (!key) {
      return
    }

    if (!that) {
      return
    }

    if (params) {
      cookie.set(key, JSON.stringify(params))
    }

    that.$router.replace({ name: key })
  }
}

export default apiPage
