<template>
  <v-theme-provider dark>
    <section id="land-footer-alt">
      <land-img
        :height="wdHeight"
        :gradient="gradient"
        :src="cover"
        flat
        max-width="100%"
        tile
      >
        <v-container
          style="max-width: 1200px; height: 100%;"
        >
          <v-row
            no-gutters
            class="ma-0 fill-height text-center"
            align="center"
          >
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-row no-gutters>
                <v-col
                  cols="6"
                >
                  <div
                    class="d-flex flex-column"
                    style="height: 50px;"
                  >
                    <land-title
                      v-if="about.title"
                      :title="about.title"
                      :space="smAndDown ? 3 : 1"
                    />
                    <land-divider
                      v-if="about.title"
                      color="white"
                      width="28"
                      space="4"
                    />
                  </div>
                  <v-container
                    class="pa-0"
                  >
                    <v-row
                      align="end"
                      no-gutters
                      style="height: 80px;"
                    >
                      <template v-if="about.items && about.items.length">
                        <v-col
                          v-for="(abItem, abIndex) in about.items"
                          :key="`ab_${abIndex}`"
                          cols="5"
                        >
                          <div
                            class="d-flex flex-row"
                            :class="[smAndDown ? 'co-text-xs' : 'co-text-sm']"
                            style="cursor: pointer;"
                            @click="onItem(abItem)"
                          >
                            {{ abItem.label }}
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col
                  cols="6"
                >
                  <div
                    class="d-flex flex-column"
                    style="height: 50px;"
                  >
                    <land-title
                      v-if="media.title"
                      :title="media.title"
                      :space="smAndDown ? 3 : 1"
                    />
                    <land-divider
                      v-if="media.title"
                      color="white"
                      width="28"
                      space="4"
                    />
                  </div>
                  <v-container
                    class="pa-0"
                  >
                    <v-row
                      align="end"
                      no-gutters
                      style="height: 80px;"
                    >
                      <template v-if="media.items && media.items.length">
                        <v-col
                          v-for="(mdItem, mdIndex) in media.items"
                          :key="`md_${mdIndex}`"
                          cols="5"
                        >
                          <v-tooltip top>
                            <template #activator="{ on, attrs }">
                              <div
                                class="co-flex-row co-items-center"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-avatar
                                  v-if="!smAndDown && mdItem.avatar"
                                  rounded="0"
                                  size="24"
                                >
                                  <v-img
                                    :src="mdItem.avatar"
                                  />
                                </v-avatar>
                                <div
                                  :class="[smAndDown ? 'co-text-xs' : 'co-text-sm ml-1']"
                                  :style="{cursor: mdItem.link ? 'pointer' : 'default'}"
                                  @click="onItem(mdItem)"
                                >
                                  {{ mdItem.label }}
                                </div>
                              </div>
                            </template>
                            <v-card
                              class="rounded pa-2 my-3"
                              color="white"
                              tile
                              flat
                            >
                              <v-img
                                width="100"
                                ratio="1"
                                :src="mdItem.qrcode"
                              />
                            </v-card>
                          </v-tooltip>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="hidden-sm-and-down"
              cols="12"
              md="6"
            >
              <v-row no-gutters>
                <v-col
                  cols="6"
                >
                  <div
                    class="d-flex flex-column"
                    style="height: 50px;"
                  >
                    <land-title
                      v-if="help.title"
                      :title="help.title"
                      class="text-uppercase"
                      space="1"
                    />
                    <land-divider
                      v-if="help.title"
                      color="white"
                      width="28"
                      space="4"
                    />
                  </div>
                  <v-container
                    class="pa-0"
                  >
                    <v-row
                      align="end"
                      no-gutters
                      style="height: 80px;"
                    >
                      <template v-if="help.items && help.items.length">
                        <v-col
                          v-for="(hpItem, hpIndex) in help.items"
                          :key="`hp_${hpIndex}`"
                          cols="5"
                        >
                          <div
                            class="d-flex flex-row co-text-sm"
                            style="cursor: pointer;"
                            @click="onItem(hpItem)"
                          >
                            {{ hpItem.label }}
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>

                <v-col
                  cols="6"
                >
                  <div
                    class="d-flex flex-column"
                    style="height: 50px;"
                  >
                    <land-title
                      v-if="link.title"
                      :title="link.title"
                      class="text-uppercase"
                      space="1"
                    />
                    <land-divider
                      v-if="link.title"
                      color="white"
                      width="28"
                      space="4"
                    />
                  </div>
                  <v-container
                    class="pa-0"
                  >
                    <v-row
                      align="end"
                      no-gutters
                      style="height: 80px;"
                    >
                      <template v-if="link.items && link.items.length">
                        <v-col
                          v-for="(lkItem, lkIndex) in link.items"
                          :key="`lk_${lkIndex}`"
                          cols="5"
                        >
                          <div
                            class="d-flex flex-row co-text-sm"
                            style="cursor: pointer;"
                            @click="onItem(lkItem)"
                          >
                            {{ lkItem.label }}
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </land-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'LandFooterAlt',
    props: {
      cover: {
        type: String,
        default: ''
      },
      content: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        points: {
          xs: 240,
          sm: 300,
          md: 360,
          lg: 360,
          xl: 360
        },
        about: {},
        help: {},
        media: {},
        link: {}
      }
    },
    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.secondary}88`
        const color2 = `${this.$vuetify.theme.themes.light.secondary}DD`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))
        // console.log({ overlay1, overlay2 })
        return `to top right, ${overlay1}, ${overlay2}`
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || '360'
        return ht
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    watch: {
      content: {
        handler (val) {
          this.about = val.about || {}
          this.help = val.help || {}
          this.media = val.media || {}
          this.link = val.link || {}
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      onItem (item) {
        this.$emit('click', item)
      }
    }
  }
</script>

<style lang="sass">
</style>
