
/**
 * 网站发布路径
 * /usr/share/nginx/html/zuimei
 */

const basePath = 'http://resources.zuimeideguang.com'
const appName = 'zuimeideguang'

const appBase = {
  prep: 'co.web',
  name: appName,
  title: '我遇见最美的光',
  version: '1.0.0',
  url_base: basePath,
  res_url: `${basePath}/${appName}`,
  res_skin: `${basePath}/${appName}/skin`,
  h5_url: `${basePath}/${appName}/h5/`,
  office_url: 'https://office.huishuapp.com',
}

export default appBase
