<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="txtName"
            label="姓名"
            outlined
            dense
            required
            :rules="nameRules"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="txtPhone"
            label="电话"
            outlined
            dense
            required
            :rules="phoneRules"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="txtEmail"
            label="邮箱"
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="txtCompany"
            label="您的单位名称"
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="txtDepartment"
            label="科室/部门"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="txtMemo"
            label="留言内容"
            outlined
            dense
            rows="5"
          />
        </v-col>
      </v-row>
      <v-row
        :justify="buttonAlign"
      >
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-btn
            depressed
            :loading="submiting"
            :color="buttonColor"
            min-width="180"
            large
            @click="onSubmit"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  export default {
    name: 'LandOnlineMessage',
    props: {
      buttonText: {
        type: String,
        default: '提交',
      },
      buttonColor: {
        type: String,
        default: 'primary',
      },
      buttonAlign: {
        type: String,
        default: 'center',
      },
      submiting: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {
        txtName: '',
        txtPhone: '',
        txtEmail: '',
        txtCompany: '',
        txtDepartment: '',
        txtMemo: '',
        nameRules: [
          val => !!val || '必须填写姓名',
          val => (val && val.length <= 32) || '姓名必须小于32个字符',
        ],
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
      }
    },
    methods: {
      reset () {
        this.txtName = ''
        this.txtPhone = ''
        this.txtEmail = ''
        this.txtCompany = ''
        this.txtDepartment = ''
        this.txtMemo = ''
      },
      onSubmit () {
        if (this.submiting) {
          return
        }
        if (!this.$refs.form.validate()) {
          return
        }

        const params = {
          name: this.txtName,
          phone: this.txtPhone,
          email: this.txtEmail,
          company: this.txtCompany,
          department: this.txtDepartment,
          memo: this.txtMemo
        }
        this.$emit('submit', params)
      }
    }
  }
</script>
