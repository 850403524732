
import api from '@/api/co.api.js'
import app from '@/api/co.app.js'

import info from '@/api/user/co.user.info.js'
import token from '@/api/user/co.user.token.js'

const userLogin = {
  // 是否登录
  isLogined (callback) {
    /*
    const result = token.get()
    const expires = parseInt(result.expires) || 0
    const currExpire = Date.now() + app.init.expireOffset

    // token 过期
    if (expires < currExpire) {
      return token.update(result, callback)
    } else {
      if (typeof callback === 'function') {
        callback({
          status: true,
          data: info.cache
        })
      }
    }
    */

    const result = info.get()
    if (result.userId) {
      if (typeof callback === 'function') {
        callback({
          status: true,
          data: result
        })
      }
    } else {
      if (typeof callback === 'function') {
        callback({
          status: false,
          data: {}
        })
      }
    }
    return true
  },
  enter ({
    data,
    executing = null,
    executed = null
  }) {
    if (!data['autoLogin']) {
      data['autoLogin'] = app.init.autoLogin
    }

    if (!data['oauth']) {
      data['oauth'] = 'password'
    }

    api.httpx.push({
      url: api.url.web_sso_login,
      data,
      executing,
      executed
    })
  },
  exit ({
    executing = null,
    executed = null
  }) {
    const trigger = function (res) {
      // console.log('co.user.login exit, res: %o', res)
      info.remove()
      token.remove()

      if (typeof executed === 'function') {
        executed(res)
      }
    }

    api.httpx.pull({
      url: api.url.web_sso_logout,
      data: null,
      executing,
      executed: trigger
    })
  }
}

export default userLogin
