
import api from '@/api/co.api'
import app from '@/api/co.app'

import comm from '@/api/socket/co.socket.comm'
import cacher from '@/api/socket/co.socket.cacher'
import receiver from '@/api/socket/co.socket.receiver'
import tasker from '@/api/socket/co.socket.tasker'

// import JSONBig from 'json-bigint'
import dayjs from 'dayjs'

const socketMain = {
  comm,
  cacher,
  receiver,
  tasker,
  initialize () {
    console.log('co.socket initialize!')
    receiver.handlers[comm.Types.ENSURE] = {
      action: receiver.toEnsure
    }

    receiver.handlers[comm.Types.SINGLE] = {
      action: receiver.toPrivate
    }

    // receiver.handlers[comm.Types.GROUP] = {
    //   action: receiver.toGroup
    // }

    this.bind({ name: 'cach_received', trigger: cacher.received })
  },
  emit (event, param = {}) {
    // console.log('co.socket emit, event: %s', event)
    // console.log('co.socket emit, param: %o', param)
    app.emit(event, param)
  },
  convertToJson (jsonStr) {
    let result = {}
    try {
      result = JSON.parse(jsonStr)
    } catch (e) {
      result = {}
    }
    return result
  },
  convert (data) {
    const result = api.comm.jsonToObject(data)

    let content = null
    if (result.type === 'chat') {
      content = result.content
    } else {
      content = api.comm.jsonToObject(result.content)
    }

    let createTime = ''
    let updateTime = ''
    let hintTime = ''

    if (result.createTime) {
      const ct = parseInt(result.createTime, 10)
      createTime = dayjs(ct).format('YYYY-MM-DD HH:mm')
      hintTime = dayjs(ct).format('M-D HH:mm')
    }

    if (result.updateTime) {
      const ut = parseInt(result.updateTime, 10)
      updateTime = dayjs(ut).format('YYYY-MM-DD HH:mm')
      if (!hintTime) {
        hintTime = dayjs(ut).format('M-D HH:mm')
      }
    }

    const to = {
      userId: result.toId,
      username: result.toUsername,
      avatar: result.toAvatar
    }

    let from = {}

    if (result.type === 'chat') {
      from = {
        userId: result.fromId || '',
        username: result.fromUsername || '',
        avatar: result.fromAvatar || ''
      }
    } else {
      from = {
        userId: content.userId || '',
        username: content.username || '',
        avatar: content.avatar || ''
      }
    }

    return {
      msgId: result.msgId,
      type: result.type,
      content,
      contentType: result.contentType,
      to,
      from,
      fromId: from.userId,
      toId: to.userId,
      state: result.state,
      createTime,
      updateTime,
      hintTime
    }
  },
  connect ({ userId }) {
    const url = `${api.url.web_im_socket}/${userId}`

    if ('WebSocket' in window) {
      tasker.handler = new WebSocket(url)
      tasker.handler.onopen = function () {
        console.log('co.socket onopen')
        tasker.opened = true
        tasker.userId = userId
        socketMain.emit(comm.Events.SOCKET_OPEN, { userId })
      }

      tasker.handler.onmessage = function (res) {
        // console.log('co.socket onmessage, res: %o', res)

        if (res.data) {
          try {
            const msg = socketMain.convert(res.data)
            cacher.add(msg)
            socketMain.trigger(msg)
          } catch (err) {
            console.warn('co.socket onmessage, data: %o', res.data)
            console.warn('co.socket onmessage, err: %o', err)
          }
        }
      }

      tasker.handler.onerror = function (res) {
        console.warn('co.socket onerror!')
        console.warn('co.socket onerror, err :%o', res)
        socketMain.emit(
          comm.Events.SOCKET_ERROR,
          {
            ...res,
            userId: tasker.userId
          }
        )
      }

      tasker.handler.onclose = function (res) {
        console.log('co.socket connect closed! res: %o', res)
        tasker.opened = false
        tasker.handler = undefined
        socketMain.emit(
          comm.Events.SOCKET_CLOSE,
          {
            userId: tasker.userId,
            code: res.code,
            isTrusted: res.isTrusted,
            reason: res.reason || '',
            type: res.type,
            wasClean: res.wasClean
          }
        )
      }
    } else {
      console.warn('co.socket WebSocket not in window!')
      socketMain.emit(comm.Events.SOCKET_NOTIN, { desc: '浏览器不支持即时通讯！' })
    }
  },
  reply ({
    messageId,
    status = comm.States.RECEIVED
  }) {
    receiver.reply({ messageId, status })
  },
  close () {
    if (tasker.handler) {
      tasker.handler.close()
    }
  },
  bind ({ name, trigger }) {
    if (!name) {
      return
    }

    tasker.dispatchers[name] = {
      name,
      trigger
    }
  },
  unbind (name) {
    if (!name) {
      return
    }
    const item = tasker.dispatchers[name]
    if (item) {
      tasker.dispatchers[name] = undefined
    }
  },
  trigger (msg) {
    for (const key in tasker.dispatchers) {
      const item = tasker.dispatchers[key]
      if (item) {
        if (typeof item.trigger === 'function') {
          item.trigger(msg)
        }
      }
    }
  },
  send (message) {
    // console.log('co.socket.main send data: %o', message)
    const prom = new Promise(function (resolve, reject) {
      if (tasker.opened && message) {
        tasker.handler.send(message)
        resolve({ status: true, message })
      } else {
        let result = ''
        if (!tasker.opened) {
          result = '还没建立通讯连接'
        }

        if (!message) {
          result = '发送信息为空'
        }

        reject(result)
      }
    })

    return prom
  }
}

export default socketMain
